import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import TagManager, { type TagManagerArgs } from 'react-gtm-module';
import { hotjar } from 'react-hotjar';
import { useFlagsStatus } from '@unleash/proxy-client-react';
import { co2ArticleData } from '@stenarecycling/customer-portal-utils';
import { useProfileV2 } from 'core';
import { checkIfInternalUser } from 'core/src/lib/utils/isInternalUser';
import { APP_ENV } from '../../utils/environment';
import { useCountries } from '../useCountries';
import useHasCo2ImpactAccess from '../useHasCo2ImpactAccess';
import { type EVENT_CONSTANT, type EVENT_ECOM_PRODUCT, type EVENT_PAYLOAD } from './constants';

const GTM_DEV_ARGS = {
  gtmId: 'GTM-NDMGDHX',
};

const GTM_PROD_ARGS = {
  gtmId: 'GTM-NDMGDHX',
};

const articleDataSE = co2ArticleData[104];
const articleDataDK = co2ArticleData[555];

export const SUBSCRIPTION_TYPE_CO2 = 'CO2'; // Do not change - shared and used for CO2 checkout request
export const SUBSCRIPTION_NAME_CO2_SE = articleDataSE.name;
export const SUBSCRIPTION_PRICE_CO2_SE = articleDataSE.price;
export const SUBSCRIPTION_CURRENCY_CO2_SE = articleDataSE.currency;
export const SUBSCRIPTION_NAME_CO2_DK = articleDataDK.name;
export const SUBSCRIPTION_PRICE_CO2_DK = articleDataDK.price;
export const SUBSCRIPTION_CURRENCY_CO2_DK = articleDataDK.currency;
export const REQUEST_NEW_LOCATION_TYPE = 'requestNewLocation';
export const REQUEST_NEW_LOCATION_NAME = 'Request for new location';

const products = {
  co2SE: {
    item_id: SUBSCRIPTION_TYPE_CO2,
    item_name: SUBSCRIPTION_NAME_CO2_SE,
    price: SUBSCRIPTION_PRICE_CO2_SE,
    currency: SUBSCRIPTION_CURRENCY_CO2_SE,
    quantity: 1,
  },
  co2DK: {
    item_id: SUBSCRIPTION_TYPE_CO2,
    item_name: SUBSCRIPTION_NAME_CO2_DK,
    price: SUBSCRIPTION_PRICE_CO2_DK,
    currency: SUBSCRIPTION_CURRENCY_CO2_DK,
    quantity: 1,
  },
  requestNewLocation: {
    item_id: REQUEST_NEW_LOCATION_TYPE,
    item_name: REQUEST_NEW_LOCATION_NAME,
    price: 0,
    currency: 'SEK',
    quantity: 1,
  },
  requestBatteryPickup: {
    item_id: 'requestBatteryPickup',
    item_name: 'Request battery pickup',
    price: 0,
    currency: 'SEK',
    quantity: 1,
  },
} as const;

type TrackEventFn = (
  event: EVENT_CONSTANT,
  provider?: 'google' | 'hotjar',
  payload?: EVENT_PAYLOAD,
) => void;

/**
 * Async version of trackEvent - only supports Google Analytics
 */
type TrackEventAsyncFn = ({
  event,
  payload,
}: {
  event: EVENT_CONSTANT;
  payload?: EVENT_PAYLOAD;
}) => Promise<void>;
type TrackPageViewFn = (pageTitle: string) => void;
type TrackVirtualPageViewFn = (pageTitle: string, pagePath: string) => void;
type TrackFileDownloadFn = (filename: string, filetype: FileType) => void;
type TrackProductViewFn = (productKey: keyof typeof products) => void;
type TrackBeginCheckoutFn = (productKey: keyof typeof products) => void;
type TrackPurchaseFn = (productKey: keyof typeof products, transactionId: string) => void;

export const GOOGLE_ECOM_PRODUCTS: Record<keyof typeof products, EVENT_ECOM_PRODUCT> = products;

type DataLayerItem = {
  [key: string]: string | number | DataLayerItem | ((e: unknown) => void);
};

type FileType = 'pdf' | 'xlsx' | 'csv' | 'png';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    dataLayer?: DataLayerItem[];
  }
}

type EventTrackingInterface = {
  trackEvent: TrackEventFn; // function that tracks events
  trackEventAsync: TrackEventAsyncFn; // async function that tracks events
  trackPageView: TrackPageViewFn;
  trackProductView: TrackProductViewFn;
  trackBeginCheckout: TrackBeginCheckoutFn;
  trackPurchase: TrackPurchaseFn;
  trackFileDownload: TrackFileDownloadFn;
  trackVirtualPageView: TrackVirtualPageViewFn;
};

type EventTrackingProps = {
  children: React.ReactNode;
};

const defaultContextInterface: EventTrackingInterface = {
  trackEvent: () => null,
  trackEventAsync: () => Promise.resolve(),
  trackPageView: () => null,
  trackProductView: () => null,
  trackBeginCheckout: () => null,
  trackPurchase: () => null,
  trackFileDownload: () => null,
  trackVirtualPageView: () => null,
};

const EventTracking = createContext<EventTrackingInterface>(defaultContextInterface);

const EventTrackingProvider = ({ children }: EventTrackingProps) => {
  const { flagsReady } = useFlagsStatus();
  const [hotjarReady, setHotjarReady] = useState(false);
  const [dataLayer, setDataLayer] = useState<DataLayerItem[]>([]);
  const [userLang, setUserLang] = useState<string | undefined>(undefined);
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [userLevel, setUserLevel] = useState<string | undefined>(undefined);
  const [userType, setUserType] = useState<string | undefined>(undefined);
  const [persona, setPersona] = useState<string | undefined>(undefined);
  const [marketCodes, setMarketCodes] = useState('');
  const [orgIds, setOrgIds] = useState<string | undefined>(undefined);
  const [subscriptionTypes, setSubscriptionTypes] = useState<string | undefined>(undefined);
  const [globalPageTitle, setGlobalPageTitle] = useState<string>('');
  const [trackVirtualPageViewState, setTrackVirtualPageViewState] = useState({
    pageTitle: '',
    pagePath: '',
  });

  const tagManagerArgs = useMemo<TagManagerArgs>(
    () => (APP_ENV === 'production' ? GTM_PROD_ARGS : GTM_DEV_ARGS),
    [],
  );

  const [GTMInitiated, setGTMInitiated] = useState(false);

  const { profile } = useProfileV2();
  const { supportedCountryCodes } = useCountries();
  const hasCo2ImpactPlus = useHasCo2ImpactAccess();

  useEffect(() => {
    if (userLang) {
      dataLayer.push({
        event: 'site_info',
        siteInfo: { languageCode: userLang },
      });
    }
  }, [dataLayer, userLang]);

  useEffect(() => {
    if (profile?.language && userLang !== profile.language) {
      setUserLang(profile.language);
    }
  }, [profile, setUserLang, userLang]);

  useEffect(() => {
    if (GTMInitiated) {
      setDataLayer(window.dataLayer ? window.dataLayer : []);
    }
  }, [GTMInitiated]);

  useEffect(() => {
    if (!GTMInitiated) {
      TagManager.initialize({
        ...tagManagerArgs,
      });
      setGTMInitiated(true); // we need to make sure tagManager is initialized before we assign the DataLayer
    }
  }, [GTMInitiated, tagManagerArgs]);

  useEffect(() => {
    if (flagsReady && !hotjarReady) {
      let hotjarId: number;

      switch (APP_ENV) {
        case 'production':
          hotjarId = 1249513;
          break;
        case 'test':
          hotjarId = 3343401;
          break;
        default: // dev and local
          hotjarId = 3343402;
          break;
      }
      hotjar.initialize({
        id: hotjarId,
        sv: 6,
      });
      setHotjarReady(true);
    }
  }, [flagsReady, hotjarReady]);

  useEffect(() => {
    if (!profile) {
      return;
    }
    if (!hotjar.initialized()) {
      return;
    }
    if (marketCodes && userType && hasCo2ImpactPlus && userLevel && hotjarReady) {
      hotjar.identify(profile.email ?? profile.userId, {
        marketCodes,
        userType,
        hasCo2ImpactPlus,
        userLevel,
      });
    }
  }, [profile, marketCodes, userType, userLevel, hotjarReady, hasCo2ImpactPlus]);

  const trackEventFn: TrackEventFn = useCallback(
    (event, provider, payload) => {
      switch (provider) {
        case 'google':
          // @ts-expect-error this ought to be allowed or I shall eat meine hat
          dataLayer.push({ event, ...(payload ?? {}) });
          break;
        case 'hotjar':
          hotjar.event(event);
          break;
        default:
          dataLayer.push({
            event,
          });
          hotjar.event(event);
      }
    },
    [dataLayer],
  );

  const trackEventAsync: TrackEventAsyncFn = useCallback(
    ({ event, payload }) => {
      return new Promise((resolve) => {
        // @ts-expect-error this ought to be allowed or I shall eat meine hat
        dataLayer.push({
          event,
          ...(payload ?? {}),
          eventCallback: (e: unknown) => {
            console.log('Event callback', e);
            resolve();
          },
        });
      });
    },
    [dataLayer],
  );

  // Exposed method to track pageviews/page title changes
  const trackPageViewFn: TrackPageViewFn = useCallback(
    (globalTitle: string) => {
      if (globalTitle !== globalPageTitle) {
        setGlobalPageTitle(globalTitle); // memoize page title for rendering consistency
      }
    },
    [globalPageTitle],
  );

  const trackVirtualPageViewFn: TrackVirtualPageViewFn = useCallback(
    (pageTitle: string, pagePath: string) => {
      if (
        pageTitle !== trackVirtualPageViewState.pageTitle ||
        pagePath !== trackVirtualPageViewState.pagePath
      ) {
        trackPageViewFn(pageTitle); // adding pageview for testing purposes
        trackEventFn('page_view', 'google', {
          page_title: pageTitle,
          page_location: window.location.origin + pagePath,
        });
        setTrackVirtualPageViewState({ pageTitle, pagePath });
      }
    },
    [
      trackEventFn,
      trackPageViewFn,
      trackVirtualPageViewState.pagePath,
      trackVirtualPageViewState.pageTitle,
    ],
  );

  // Exposed method to track product view
  const trackProductViewFn: TrackProductViewFn = useCallback(
    (productKey) => {
      const event = 'view_item';
      const payload: EVENT_PAYLOAD = {
        ecommerce: {
          items: [GOOGLE_ECOM_PRODUCTS[productKey]],
        },
      };

      trackEventFn(event, 'google', payload);
    },
    [trackEventFn],
  );

  // Exposed method to track begin checkout
  const trackBeginCheckoutFn: TrackBeginCheckoutFn = useCallback(
    (productKey) => {
      const event = 'begin_checkout';
      const payload: EVENT_PAYLOAD = {
        ecommerce: {
          items: [GOOGLE_ECOM_PRODUCTS[productKey]],
        },
      };

      trackEventFn(event, 'google', payload);
    },
    [trackEventFn],
  );

  // Exposed method to track completed purchase
  const trackPurchaseFn: TrackPurchaseFn = useCallback(
    (productKey, transactionId) => {
      const event = 'purchase';
      const payload: EVENT_PAYLOAD = {
        ecommerce: {
          transaction_id: transactionId,
          currency: GOOGLE_ECOM_PRODUCTS[productKey].currency,
          value: GOOGLE_ECOM_PRODUCTS[productKey].price,
          items: [GOOGLE_ECOM_PRODUCTS[productKey]],
        },
      };

      trackEventFn(event, 'google', payload);
    },
    [trackEventFn],
  );

  useEffect(() => {
    if (globalPageTitle !== '') {
      // trigger only on local state change (for memoization)
      dataLayer.push({
        event: 'page_info',
        pageInfo: { globalPageTitle },
      });
    }
  }, [dataLayer, globalPageTitle]);

  //Track supported markets
  useEffect(() => {
    if (supportedCountryCodes.length !== 0) {
      setMarketCodes(supportedCountryCodes.sort().toString());
    }
  }, [supportedCountryCodes]);

  // Track userId
  useEffect(() => {
    if (profile?.userId) {
      setUserId(profile.userId);
    }
  }, [profile?.userId]);

  // Track orgId
  useEffect(() => {
    if (profile?.permissions?.businessPartners) {
      setOrgIds(profile.permissions.businessPartners.map((bp) => bp.id).toString());
    }
  }, [profile?.permissions?.businessPartners]);

  // Track subscription types
  useEffect(() => {
    const types: string[] = [];

    if (hasCo2ImpactPlus) {
      types.push('CO2 Impact Plus');
    }

    setSubscriptionTypes(types.toString());
  }, [hasCo2ImpactPlus]);

  // Track user level
  useEffect(() => {
    if (profile?.permissions?.superUser) {
      setUserLevel('superuser');
    } else {
      setUserLevel('user');
    }
  }, [profile]);

  // Track user type
  useEffect(() => {
    if (checkIfInternalUser(profile?.email)) {
      setUserType('internal');
    } else {
      setUserType('external');
    }
  }, [profile]);

  // Track persona
  useEffect(() => {
    setPersona('user');
  }, []);

  //Push userInfo event to datalayer
  useEffect(() => {
    if (userId) {
      dataLayer.push({
        event: 'user_info',
        userInfo: {
          marketCodes: marketCodes,
          userID: userId,
          orgID: orgIds ?? '',
          subscriptionTypes: subscriptionTypes ?? '',
          userType: userType ?? '',
          userLevel: userLevel ?? '',
          persona: persona ?? '',
        },
      });
    }
  }, [dataLayer, marketCodes, userId, orgIds, subscriptionTypes, userLevel, userType, persona]);

  // Exposed method to track file downloads
  const trackFileDownloadFn: TrackFileDownloadFn = useCallback(
    (name, type) => {
      dataLayer.push({ event: 'downloadDocument', documentInfo: { name, type } });
    },
    [dataLayer],
  );

  const trackPrintFn = useCallback(() => {
    dataLayer.push({
      event: 'downloadDocument',
      documentInfo: { name: 'print', type: 'pdf' },
    });
  }, [dataLayer]);

  useEffect(() => {
    window.addEventListener('beforeprint', trackPrintFn);

    return () => {
      window.removeEventListener('beforeprint', trackPrintFn);
    };
  }, [trackPrintFn]);

  const eventTrackingInterface: EventTrackingInterface = useMemo(
    () => ({
      trackEvent: trackEventFn,
      trackEventAsync,
      trackPageView: trackPageViewFn,
      trackProductView: trackProductViewFn,
      trackBeginCheckout: trackBeginCheckoutFn,
      trackPurchase: trackPurchaseFn,
      trackFileDownload: trackFileDownloadFn,
      trackPrint: trackPrintFn,
      trackVirtualPageView: trackVirtualPageViewFn,
    }),
    [
      trackEventFn,
      trackEventAsync,
      trackPageViewFn,
      trackFileDownloadFn,
      trackProductViewFn,
      trackBeginCheckoutFn,
      trackPurchaseFn,
      trackPrintFn,
      trackVirtualPageViewFn,
    ],
  );

  return <EventTracking.Provider value={eventTrackingInterface}>{children}</EventTracking.Provider>;
};

const useEventTracking = (): EventTrackingInterface => useContext(EventTracking);

export { EventTrackingProvider, useEventTracking };
