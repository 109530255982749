import { CopyText, InnerFooter, LowerFooter, StyledFooter, UpperFooter } from '../../Footer/styles';
import LogoIcon from '../../Footer/LogoIcon';

export const FooterPlaceholder = () => {
  return (
    <StyledFooter>
      <UpperFooter>
        <InnerFooter>
          <LogoIcon />
        </InnerFooter>
      </UpperFooter>
      <LowerFooter>
        <InnerFooter>
          <CopyText>Copyright © Stena Recycling AB</CopyText>
        </InnerFooter>
      </LowerFooter>
    </StyledFooter>
  );
};
