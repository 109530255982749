import { useContext } from 'react';
import { ProfileContext } from './ProfileProvider';

/*
 * This is the new version of the useProfile hook.
 * It returns an object instead of an array.
 * Made since the old version is used in a lot of places and it's hard to change it everywhere.
 * The old version will be deprecated in the future.
 * */
export const useProfileV2 = () => {
  const context = useContext(ProfileContext);

  if (!context) {
    throw Error('Wrap your app with `ProfileProvider`');
  } else {
    return context;
  }
};
