import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../themes/stena-recycling';
import { animationTransition } from '../../helpers/styleHelpers';

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledToggle = styled.button<{ checked: boolean; disabled?: boolean }>`
  position: relative;
  width: ${spacing.xlarge};
  height: ${spacing.mediumLow};
  border-radius: ${spacing.mediumLow};
  border: none;
  padding: ${spacing.tiny};
  background: ${({ checked }) => (checked ? colors.primary.blue : colors.monochrome.grey40)};
  transition: background-color ${animationTransition};
  cursor: pointer;

  &:hover:not(:disabled) {
    background: ${({ checked }) => {
      if (checked) {
        return colors.primary.blue;
      }

      return colors.monochrome.grey50;
    }};
  }

  &:disabled {
    cursor: unset;
    background: ${colors.monochrome.grey20};
  }
`;

export const Switch = styled.div<{ checked: boolean }>`
  position: absolute;
  width: ${spacing.small};
  height: ${spacing.small};
  background: ${colors.base.white};
  top: 13%;
  border-radius: 50%;
  transform: ${({ checked }) => (checked ? 'translateX(132%)' : 'translateX(0)')};
  transition: transform ${animationTransition};
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  ${typography.other.inputLabel}
  margin-right: ${spacing.xmsmall};
`;
