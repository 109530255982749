import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../themes';

export const PageHeaderWrapper = styled.div<{ center: boolean }>`
  display: flex;
  align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
  flex-direction: column;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
`;

export const Area = styled.div`
  ${typography.heading.desktop.sCaps};
  margin-bottom: ${spacing.xxsmall};
`;

export const Divider = styled.div`
  box-sizing: border-box;
  width: 60px;
  border-bottom: 2px solid ${colors.base.black};
  margin-bottom: ${spacing.small};
`;

export const WelcomeText = styled.div`
  ${typography.heading.desktop.small};
`;
