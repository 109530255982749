import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../../../../../../../themes';

export const StyledWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const DropdownSearchWrapper = styled.div`
  display: flex;
  padding: ${spacing.xsmall};
  border-bottom: 1px solid ${colors.monochrome.grey20};
`;

export const StyledSearchTitle = styled.div`
  ${typography.body.large};
  padding: ${spacing.xmsmall};
  border-bottom: 1px solid ${colors.monochrome.grey20};
`;

export const StyledEmptySearchMessage = styled.div`
  ${typography.body.large};
  color: ${colors.primary.grey};
  padding: ${spacing.xmsmall};
  height: 260px;
`;
