import styled from '@emotion/styled';
import { spacing } from '../../../../../../themes';

export const InputWrapper = styled.div`
  position: relative;
`;
export const ButtonWrapper = styled.div`
  position: absolute;
  top: ${spacing.xxsmall};
  right: ${spacing.xxsmall};
`;
