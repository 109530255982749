import styled from '@emotion/styled';
import { typography } from '../../../themes/stena-recycling/typography';
import { colors, spacing } from '../../../themes';
import { type ParagraphProps } from '.';

export const StyledParagraph = styled.p<ParagraphProps>`
  ${({ variant, bold }) => {
    switch (variant) {
      case 'small':
        if (bold) {
          return typography.body.smallBold;
        }

        return typography.body.small;
      case 'medium':
        if (bold) {
          return typography.body.mediumBold;
        }

        return typography.body.medium;
      case 'large':
      default:
        if (bold) {
          return typography.body.largeBold;
        }

        return typography.body.large;
    }
  }}
  color: ${({ color }) => (color ? color : colors.base.black)};
  ${({ mt }) => mt && `margin-top: ${spacing[mt]}`};
  ${({ mb }) => mb && `margin-bottom: ${spacing[mb]}`};
`;
