import { useEffect } from 'react';
import { useAppContainerContext } from '../AppContainer/useAppContainerContext';
import { PageContainerWrapper, PageContainerModalWrap } from './styles';

type PageContainerProps = {
  children: React.ReactNode;
  backgroundColor?: string;
};

export const PageContainer = ({ backgroundColor, children }: PageContainerProps) => {
  const { setBackgroundColor } = useAppContainerContext();

  useEffect(() => {
    if (backgroundColor) {
      setBackgroundColor(backgroundColor);
    }
  }, [backgroundColor, setBackgroundColor]);

  return (
    <PageContainerModalWrap>
      <PageContainerWrapper>{children}</PageContainerWrapper>
    </PageContainerModalWrap>
  );
};
