import styled from '@emotion/styled';
import { breakpoint, colors, spacing } from 'component-library';
import { maxmq } from '../../assets/styles/breakpoints';

export const MobileSubMenuWrapper = styled.div`
  display: none;
  align-items: center;
  background-color: ${colors.base.white};
  border-bottom: 1px solid ${colors.monochrome.grey20};
  gap: ${spacing.xmsmall};
  padding-right: var(--active-modal-padding);
  justify-content: space-between;

  ${maxmq[2]} {
    display: flex;
  }

  padding: ${spacing.xsmall} ${spacing.mediumLow};
  @media (max-width: ${breakpoint.xlarge}px) {
    padding-left: ${spacing.mediumHigh};
    padding-right: ${spacing.mediumHigh};
  }

  @media (max-width: ${breakpoint.small}px) {
    flex-direction: column;
    padding-left: ${spacing.small};
    padding-right: ${spacing.small};
  }
`;

export const SubButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors.primary.blue};
  cursor: pointer;
  border: none;
  background: ${colors.base.white};
  width: 100%;
  max-width: 200px;
  @media (max-width: ${breakpoint.small}px) {
    max-width: unset;
  }
`;
