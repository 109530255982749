import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../../../../../../../themes';
import { hoverCheckboxStyle } from '../../../../../../styles';

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing.xmsmall};
  flex-direction: row;
  border-bottom: 1px solid ${colors.monochrome.grey20};
  gap: ${spacing.mediumLow};
`;

export const SelectAllWrapper = styled.div`
  display: flex;
  ${hoverCheckboxStyle}
`;

export const StyledTitle = styled.span`
  padding-left: ${spacing.xmsmall};
  ${typography.body.large};
  cursor: pointer;
`;
