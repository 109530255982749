import styled from '@emotion/styled';
import { boxShadow, colors, spacing, typography } from 'component-library';

export const LinkDropDownWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledLinkDropDown = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors.primary.blue};
  cursor: pointer;
  border: none;
  background: ${colors.base.white};
  width: 100%;

  padding: 0;
`;

export const LinkDropDownButton = styled.div`
  padding: ${spacing.xmsmall} ${spacing.small};
`;

export const LinkDropDownItem = styled.div<{ active?: boolean }>`
  padding: ${spacing.xmsmall} ${spacing.small};
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  ${typography.other.subMenuLabel};
  background-color: ${({ active }) => (active ? colors.monochrome.grey10 : colors.base.white)};
  :hover {
    background-color: ${colors.monochrome.grey10};
  }
`;

export const LinkDropDownItemsList = styled.div`
  width: 100%;
  position: absolute;
  top: 100%;
  left: auto;
  right: 0;
  background: ${colors.base.white};
  display: flex;
  flex-direction: column;
  z-index: 10;
  box-shadow: ${boxShadow};
`;

export const LinkDropDownItemText = styled.span`
  text-align: left;
  ${typography.other.subMenuLabel};
  color: ${colors.primary.blue};
`;
