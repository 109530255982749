import styled from '@emotion/styled';
import { type Spacing, spacing } from '../../../themes/stena-recycling/spacing';
import { colors } from '../../../themes';

export const StyledDivider = styled.div<{
  p?: Spacing;
  pt?: Spacing;
  pb?: Spacing;
  m?: Spacing;
  mt?: Spacing;
  mb?: Spacing;
}>`
  width: 100%;
  border-top: 1px solid ${colors.monochrome.grey30};
  ${(props) => props.p && `padding: ${spacing[props.p]}`};
  ${(props) => props.pt && `padding-top: ${spacing[props.pt]}`};
  ${(props) => props.pb && `padding-bottom: ${spacing[props.pb]}`};
  ${(props) => props.m && `margin: ${spacing[props.m]}`};
  ${(props) => props.mt && `margin-top: ${spacing[props.mt]}`};
  ${(props) => props.mb && `margin-bottom: ${spacing[props.mb]}`};
`;
