import styled from '@emotion/styled';
import { spacing } from 'component-library';

export const MessageContainer = styled.div<{
  type: string;
  border: string;
  mb?: string;
  mt?: string;
}>`
  margin-bottom: ${(props) => props.mb};
  margin-top: ${(props) => props.mt};
  background-color: ${(props) => props.type};
  border: 1px solid ${(props) => props.border};
  border-radius: 8px;
`;

export const MessageBox = styled.div`
  padding: ${spacing.small};
  position: relative;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: ${spacing.xxsmall};
  right: ${spacing.xxsmall};
`;

export const TextWrapper = styled.div`
  overflow-wrap: anywhere;

  /* Hack to block text from falling under close button */
  &::before {
    display: block;
    content: '';
    float: right;
    width: 10px;
    height: 10px;
  }
`;
