export const remBase = 16;
export const remScale = 1;

/**
 * Functions to convert between rem and px
 * @param px, accepts Int, String with number and string with px
 */
export function rem(px: number | string): string {
  if (typeof px === 'string') {
    if (px.includes('px')) px = px.replace('px', '');

    px = parseInt(px);
  }

  return `${px / (remBase * remScale)}rem`;
}
