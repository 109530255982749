import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../../themes/stena-recycling';
import { inputBorderStyle, inputFieldStyles } from '../../../helpers/inputHelpers';

export const TextAreaWrapper = styled.div<{ fullWidth?: boolean }>`
  ${({ fullWidth }) => fullWidth && 'width: 100%'}
  flex-direction: column;
`;

export const StyledTextArea = styled.textarea<{ error: boolean }>`
  ${({ error }) => inputBorderStyle(error)}
  ${({ error }) => inputFieldStyles(error)}
  resize: none;
  padding: ${spacing.xmsmall} ${spacing.xmsmall} ${spacing.xxsmall} ${spacing.xmsmall};

  &:focus {
    padding: calc(${spacing.xmsmall} - 1px) calc(${spacing.xmsmall} - 1px) ${spacing.xxsmall}
      calc(${spacing.xmsmall} - 1px);
    margin-bottom: -1px;
  }
`;

export const TextAreaFooter = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextAreaError = styled.span`
  ${typography.body.medium}
  letter-spacing: 0.5px;
  color: ${colors.alerts.error};
`;

export const CharacterCounter = styled.span`
  ${typography.body.medium}
  margin-left: auto;
`;
