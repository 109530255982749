import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../themes';
import { rem } from '../../../themes/stena-recycling/utils/fontScaling';

export const TableWrapper = styled.div`
  position: relative;
  border: 1px solid ${colors.monochrome.grey20};
`;

export const TableContent = styled.div`
  overflow-x: auto;
`;

export const TableHeaderRow = styled.div<{ totalColumns: number }>`
  display: grid;
  grid-template-columns: repeat(${({ totalColumns }) => totalColumns}, 1fr);
  grid-gap: ${spacing.xsmall};
  padding: 0 ${spacing.xsmall};
  border-bottom: 1px solid ${colors.monochrome.grey50};
  min-height: 57px;
`;

export const StyledTableRow = styled.div<{
  totalColSpan?: number;
  hasAccordionContent?: boolean;
  alternateBackground?: boolean;
  isSummarize?: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(${({ totalColSpan }) => totalColSpan}, 1fr);
  grid-gap: ${spacing.xsmall};
  padding: ${spacing.xsmall};
  border-bottom: 1px solid ${colors.monochrome.grey20};
  cursor: ${(props) => (props.hasAccordionContent ? 'pointer' : 'default')};

  &:hover {
    background-color: ${(props) =>
      props.hasAccordionContent ? colors.background.grey : 'transparent'};
  }

  &:nth-of-type(even) {
    background-color: ${(props) =>
      props.alternateBackground ? colors.background.grey : 'transparent'};
  }
  &:last-child {
    border-bottom: none;
    background-color: ${({ isSummarize }) => (isSummarize ? colors.background.grey : 'inherit')};
  }
`;

export const TableCell = styled.div<{ colSpan?: number; justify?: string }>`
  font-family: inherit;
  ${typography.body.large};
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column: auto / span ${({ colSpan }) => colSpan};
  ${(props) => props.justify && `justify-content: ${props.justify};`}
`;

export const AccordionContent = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.monochrome.grey20};

  &:last-child {
    border-bottom: none;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.small};
  padding: ${spacing.xmsmall} ${spacing.xsmall} ${spacing.xsmall};
`;

type ScrollWrapperType = {
  totalColSpan: number;
  count: number;
  rowMinWidth?: number;
};

export const ScrollWrapper = styled.div<ScrollWrapperType>`
  ${({ totalColSpan, rowMinWidth }) => {
    if (rowMinWidth) {
      return `width: ${rowMinWidth}px;`;
    }
    if (totalColSpan) {
      return `width: ${rem(totalColSpan * 30)};`;
    }
  }}
  min-width: 100%;
`;

export const EmptyTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.small};
`;
