import { FlagContext, useUnleashClient, useUnleashContext } from '@unleash/proxy-client-react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useProfileV2 } from '../Profile';

export const InitUnleash = () => {
  const { basicProfile } = useProfileV2();
  const [initialized, setInitialized] = useState(false);
  const updateContext = useUnleashContext();
  const unleashClient = useUnleashClient();
  const { setFlagsError, setFlagsReady } = useContext(FlagContext);

  const startUnleash = useCallback(
    async (userId: string) => {
      await updateContext({ userId });
      await unleashClient.start();
      unleashClient.on('error', () => {
        setFlagsError(true);
        setFlagsReady(true);
      });
    },
    [updateContext, unleashClient, setFlagsError, setFlagsReady],
  );

  useEffect(() => {
    if (initialized) {
      return;
    }
    const userId = basicProfile?.email;

    if (userId) {
      startUnleash(userId)
        .then(() => {
          setInitialized(true);
        })
        .catch(() => {
          setFlagsError(true);
          setFlagsReady(true);
        });
    }
  }, [basicProfile, initialized, startUnleash, setFlagsError, setFlagsReady]);

  return null;
};
