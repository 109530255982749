import styled from '@emotion/styled';
import { breakpoint, colors, spacing, typography } from 'component-library';

export const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  @media (max-width: ${breakpoint.small}px) {
    flex-direction: column;
  }
`;

export const InnerFooter = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${breakpoint.xlarge + 96}px) {
    padding-left: ${spacing.mediumHigh};
    padding-right: ${spacing.mediumHigh};
  }

  @media (max-width: ${breakpoint.small}px) {
    padding-left: ${spacing.small};
    padding-right: ${spacing.small};

    flex-direction: column;
    align-items: flex-start;
  }
`;

export const UpperFooter = styled.div`
  padding: ${spacing.mediumLow} 0;
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: ${colors.base.black};
  padding-right: var(--active-modal-padding);
`;

export const LowerFooter = styled.div`
  padding: ${spacing.xsmall} 0;
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: ${colors.monochrome.grey90};
  padding-right: var(--active-modal-padding);
`;

export const CopyText = styled.span`
  color: ${colors.base.white};
  ${typography.body.small};
`;

export const LinkWrapper = styled.div`
  display: flex;
  gap: ${spacing.small};
  flex-direction: row;
  @media screen and (max-width: ${breakpoint.small}px) {
    margin-top: ${spacing.mediumLow};
    flex-direction: column;
  }
`;
export const Link = styled.a`
  color: #ffffff;
  ${typography.body.large};
  text-decoration: none;
  &:hover {
    color: ${colors.primary.blueTint3};
  }
`;
