import styled from '@emotion/styled';
import { breakpoint, colors, spacing } from 'component-library';

export const SubMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media screen and (max-width: ${breakpoint.medium}px) {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

type StyledSubATagProps = {
  active: boolean;
};

export const StyledSubATag = styled.a<StyledSubATagProps>`
  font-weight: 400;
  font-size: 1rem;
  line-height: ${spacing.small};
  text-decoration: none;
  cursor: pointer;
  color: ${({ active }) => (active ? colors.primary.blue : colors.base.black)};
  padding: ${spacing.xsmall} 0;
  &:not(:last-child) {
    margin-right: ${spacing.mediumLow};
  }
  &:hover {
    color: ${colors.primary.blue};
  }
`;
