import styled from '@emotion/styled';
import { colors, spacing } from 'component-library';

export const UserIconButtonWrapper = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${spacing.medium};
  min-width: ${spacing.medium};
  border-radius: 50%;
  &:hover {
    border: 1px solid ${colors.primary.grey};
  }
  &:focus {
    background-color: ${colors.primary.grey};
  }
`;
