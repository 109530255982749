import styled from '@emotion/styled';
import { Container } from '../../Surfaces/Container';
import { boxShadow } from '../../helpers/styleHelpers';
import { breakpoint } from '../../../themes/stena-recycling/breakpoint';
import { spacing } from '../../../themes/stena-recycling/spacing';

export const LinkCardWrapper = styled(Container)<{ mobileBreakpoint?: number; vertical?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: ${spacing.xsmall};
  padding: ${spacing.small};
  transition: box-shadow 0.3s ease-out;
  justify-content: space-between;
  flex: 1;
  ${({ vertical }) =>
    !vertical &&
    `
  max-width: 50%;
  min-width: 250px;
  `}

  :hover {
    cursor: pointer;
    box-shadow: ${boxShadow};
  }

  @media (max-width: ${({ mobileBreakpoint }) =>
      mobileBreakpoint ? mobileBreakpoint : breakpoint.medium}px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const IconLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 32px;
`;

export const LinkCardTextWrapper = styled.div<{ vertical?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ vertical }) =>
    vertical &&
    `
    justify-content: center;    
  `}
  @media (max-width: ${breakpoint.medium}px) {
    justify-content: center;
  }
`;
