import {
  type PartnerResItem,
  type PartnerTreeNodeV2,
  type StenaPermissions,
} from '@stenarecycling/customer-portal-types';
import { inflate } from 'pako';
import { getApiUrl } from '../utils/environment';

export const fetchBusinessPartners = async ({
  accessToken,
  transactionId,
  dwKeys,
}: {
  accessToken: string;
  transactionId: string;
  dwKeys: string[];
}): Promise<{ partners: PartnerTreeNodeV2[]; missingPartnersInApiCount: number }> => {
  if (dwKeys.length) {
    const profileBusinessPartnersRequests = dwKeys.map((bp) => {
      const url = `${getApiUrl()}/v2/partners/${bp}/hierarchy?allDescendants=true&slim=true&compress=true`;

      const headers = new Headers();

      headers.set('Authorization', `Bearer ${accessToken}`);
      headers.set('X-Transaction-Id', transactionId);

      return fetch(url, { headers })
        .then((d) => {
          if (!d.ok) {
            return Promise.reject(new Error('Failed to call ' + url));
          }

          return d.text();
        })
        .then((base64Data) => {
          const compressedData = Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0));
          const uncompressedData = inflate(compressedData, { to: 'string' });

          return JSON.parse(uncompressedData) as PartnerTreeNodeV2;
        })
        .then((res) => {
          replaceNARecursive(res);

          return res;
        })
        .catch((e) => {
          console.error(e);

          return Promise.resolve({} as PartnerTreeNodeV2);
        });
    });

    const bps = await Promise.all<PartnerTreeNodeV2>(profileBusinessPartnersRequests);

    const parsedBps = bps.reduce<PartnerTreeNodeV2[]>((prev, curr) => {
      if (curr.item) {
        return prev.concat(curr);
      }

      return prev;
    }, []);

    const missingInApi = dwKeys.length - parsedBps.length;

    return { partners: parsedBps, missingPartnersInApiCount: missingInApi };
  }

  return {
    partners: [],
    missingPartnersInApiCount: 0,
  };
};

const replaceNARecursive = (node: PartnerTreeNodeV2) => {
  if (node.item.street === 'N/A') {
    node.item.street = '';
  }
  if (node.item.city === 'N/A') {
    node.item.city = '';
  }
  if (node.item.additionalDescription === 'N/A') {
    node.item.additionalDescription = '';
  }
  if (node.item.customerText === 'N/A') {
    node.item.customerText = '';
  }
  if (node.children) {
    node.children.forEach((child) => replaceNARecursive(child));
  }

  return node;
};

export const getDwkeysFromPartnerTrees = (partnerTrees: PartnerTreeNodeV2[]): string[] => {
  // Recursively get all dwKeys from partnerTrees
  const allDwKeys: string[] = [];

  const getDwKeys = (partnerTrees: PartnerTreeNodeV2[]) => {
    partnerTrees.forEach((partnerTree) => {
      partnerTree.item.dwKey.length && allDwKeys.push(partnerTree.item.dwKey);
      if (partnerTree.children.length) {
        getDwKeys(partnerTree.children);
      }
    });
  };

  getDwKeys(partnerTrees);

  return allDwKeys;
};

export const filterPartnersTreeOnService = ({
  serviceName,
  partnerTrees,
  permissions,
}: {
  partnerTrees: PartnerTreeNodeV2[];
  serviceName: string;
  permissions: StenaPermissions;
}): PartnerTreeNodeV2[] => {
  const allPartnerIdsInService = permissions.businessPartners
    .filter((bp) => bp.services.some((s) => s.name === serviceName))
    .flatMap((bp) => [bp.id, ...(bp.expandedPartners || [])]);

  return partnerTrees.filter((partnerTree) => {
    return allPartnerIdsInService.includes(partnerTree.item.dwKey);
  });
};

export const filterPartnersTreeOnConfidential = ({
  partnerTrees,
}: {
  partnerTrees: PartnerTreeNodeV2[];
}): PartnerTreeNodeV2[] => {
  return partnerTrees.filter((partnerTree) => {
    return !partnerTree.item.dwKey.startsWith('90');
  });
};

export const makePartnerLabels = (partner: PartnerResItem, includeZip?: boolean) => {
  const filterFunc = (item?: string) =>
    item !== null && item !== undefined && item !== 'N/A' && item !== '.' && item.length > 0;

  const descArr = [
    (includeZip && filterFunc(partner.postalCode) ? partner.postalCode + ' ' : '') + partner.city,
    partner.street,
  ];
  const descriptionArr = descArr.filter(filterFunc);
  const labelArr = [partner.name, partner.customerText];

  // If no customerText, use additionalDescription instead
  if (!filterFunc(partner.customerText)) {
    labelArr.push(partner.additionalDescription);
  }

  const description = descriptionArr.join(', ') + ` (${partner.id})`;
  const label = labelArr.filter(filterFunc).join(', ');

  return {
    description,
    label,
  };
};
