import styled from '@emotion/styled';
import { colors, spacing, typography } from 'component-library';
import { maxmq } from '../assets/styles/breakpoints';

export const DropDownMenuWrapper = styled.div`
  position: relative;
`;

export const DropDownMenuInnerWrapper = styled.div`
  z-index: 5;
  box-sizing: border-box;
  position: absolute;
  top: 37px;
  right: 0px;
  background-color: ${colors.base.white};
  min-width: 337px;
  width: fit-content;
  max-width: 100vw;
  color: ${colors.base.black};
  border: 1px solid ${colors.monochrome.grey10};
  /* Dropdown shadow */

  box-shadow: 0px 15px 40px rgba(51, 51, 51, 0.15);
  border-radius: 4px;
  ${maxmq[0]} {
    min-width: 100vw;
    right: -24px;
  }
`;

export const LogoutIconWrapper = styled.div`
  color: ${colors.base.black};
`;

export const UserInfo = styled.div`
  padding: ${spacing.small} ${spacing.small} ${spacing.xsmall} ${spacing.small};
  border-bottom: 1px solid ${colors.monochrome.grey20};
`;

export const Name = styled.p`
  ${typography.body.largeBold};
  margin: 0;
`;

export const Email = styled.p`
  color: ${colors.primary.grey};
  ${typography.body.medium};
`;
export const BottomPart = styled.div`
  padding: ${spacing.xsmall} ${spacing.small} ${spacing.small} ${spacing.small};
`;

export const LogoutButton = styled.button`
  font-family: inherit;
  ${typography.other.subMenuLabel};
  gap: ${spacing.xsmall};
  justify-content: flex-start;
  align-items: center;

  margin: 0;
  color: ${colors.base.black};
  padding: 0;
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    color: ${colors.monochrome.grey50};
  }
`;

export const Link = styled.a`
  display: flex;
  gap: ${spacing.xsmall};
  font-size: 1rem;
  line-height: 24px;
  color: ${colors.base.black};
  text-decoration: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  &:hover {
    color: ${colors.monochrome.grey50};
  }
`;

export const LinkIconWrapper = styled.div`
  color: ${colors.base.black};
`;

export const LinkWrapper = styled.div`
  margin-bottom: ${spacing.xsmall};
  display: flex;
  justify-content: flex-start;
`;
