import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

const uuid = uuidv4();

export const useTransactionId = () => {
  const transactionId = useRef(uuid);

  return transactionId.current;
};
