import styled from '@emotion/styled';
import { colors, spacing } from '../../../../../themes';
import { typography } from '../../../../../themes/stena-recycling/typography';

export const CellWrapper = styled.div<{
  sortingDefined: boolean;
  colSpan?: number;
  justify?: 'left' | 'center' | 'right';
}>`
  color: ${colors.primary.grey};
  font-family: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${typography.body.mediumBold};
  grid-column: auto / span ${(props) => props.colSpan};
  ${(props) => props.justify && `justify-content: ${justifyToFlex(props.justify)};`}
  ${(props) => (props.justify === 'right' ? 'text-align: right;' : '')}
  gap: ${spacing.xxsmall};

  &:hover {
    cursor: ${({ sortingDefined }) => (sortingDefined ? 'pointer' : 'default')};
    color: ${({ sortingDefined }) => (sortingDefined ? colors.base.black : colors.primary.grey)};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  color: inherit;
  ${typography.body.mediumBold};
`;

export const Label = styled.div`
  color: inherit;
  ${typography.body.small};
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.xxsmall};
`;

export const IconWrapper = styled.div`
  display: flex;
`;

export const SortIconWrapper = styled.div`
  width: ${spacing.xsmall};
  height: ${spacing.xsmall};
`;

const justifyToFlex = (justify: 'left' | 'center' | 'right') => {
  switch (justify) {
    case 'left':
      return 'flex-start';
    case 'center':
      return 'center';
    case 'right':
      return 'flex-end';
    default:
      return 'flex-start';
  }
};
