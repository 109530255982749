import styled from '@emotion/styled';
import { colors } from '../../../../../../../themes';

export const DropdownErrorMessageStyled = styled.div`
  color: ${colors.alerts.error};
  z-index: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;
