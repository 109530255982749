import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../themes';
import { boxShadow } from '../../helpers/styleHelpers';

export const TooltipWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

export const Spacer = styled.div`
  position: relative;
`;

export const TooltipContent = styled.div<{ isOpen: boolean; isRight?: boolean }>`
  position: absolute;
  transition:
    visibility 0.15s,
    opacity 0.15s linear;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  background-color: ${colors.base.white};
  z-index: 1;
  margin-top: ${spacing.xxsmall};
  box-sizing: border-box;
  padding: ${spacing.xsmall};
  width: 300px;
  box-shadow: ${boxShadow};
  border-radius: 4px;
  top: 0;
  left: ${({ isRight }) => (isRight ? 'auto' : '0')};
  right: ${({ isRight }) => (isRight ? '0' : 'auto')};
  ${typography.body.medium};
  color: ${colors.base.black};
`;

export const RightAlignedTooltip = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
