import styled from '@emotion/styled';
import { colors, typography } from 'component-library';

export const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PartnerDescription = styled.span`
  ${typography.body.medium};
  color: ${colors.primary.grey};
  display: block;
`;
