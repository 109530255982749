export const typography = {
  fontFamily: 'StenaSans, -apple-system, BlinkMacSystemFont, sans-serif',
  heading: {
    desktop: {
      mega: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '48px',
        lineHeight: '56px',
        letterSpacing: '1.2px',
      },
      xLarge: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '40px',
        lineHeight: '48px',
        letterSpacing: '1.2px',
      },
      large: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '32px',
        lineHeight: '40px',
        letterSpacing: '1.2px',
      },
      medium: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: '1.2px',
      },
      small: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '28px',
        letterSpacing: '1.2px',
      },
      sCaps: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '28px',
        letterSpacing: '2px',
        textTransform: 'uppercase' as const,
      },
    },
    mobile: {
      mega: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '40px',
        lineHeight: '48px',
        letterSpacing: '1.2px',
      },
      xLarge: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '32px',
        lineHeight: '40px',
        letterSpacing: '1.2px',
      },
      large: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: '1.2px',
      },
      medium: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '28px',
        letterSpacing: '1.2px',
      },
      small: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '26px',
        letterSpacing: '1.2px',
      },
      sCaps: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '26px',
        letterSpacing: '1.2px',
        textTransform: 'uppercase' as const,
      },
    },
  },
  body: {
    large: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
    },
    largeBold: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
    },
    medium: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
    },
    mediumBold: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '20px',
    },
    small: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
    },
    smallBold: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  other: {
    button: {
      label: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '2.5px',
        textTransform: 'uppercase' as const,
      },
      secondaryLabel: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    inputLabel: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    inputValue: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    mainMenuLabel: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '38px',
      display: 'flex',
      letterSpacing: '2.5px',
      textTransform: 'uppercase' as const,
    },
    subMenuLabel: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      display: 'flex',
    },
    burgerMenuLabel: {
      fontWeight: 700,
      fontSize: '24x',
      lineHeight: '32px',
      display: 'flex',
      letterSpacing: '2.5px',
      textTransform: 'uppercase' as const,
    },
  },
};
