import styled from '@emotion/styled';
import { colors, spacing, typography } from 'component-library';
import { Link } from 'react-router-dom';
import { maxmq } from '../../assets/styles/breakpoints';

export const MenuLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.mediumLow};
  width: 100%;
  padding: 0 ${spacing.medium};
  ${maxmq[2]} {
    display: none;
  }
`;

type MenuLinkProps = {
  isActive: boolean;
};

export const StyledATag = styled.a<MenuLinkProps>`
  ${typography.other.mainMenuLabel};
  text-decoration: none;
  color: ${colors.base.white};
  border-bottom: ${({ isActive }) => (isActive ? `2px solid ${colors.base.white}` : 'none')};
  &:hover {
    border-bottom: 2px solid ${colors.primary.grey};
`;

export const StyledLink = styled(Link, {
  shouldForwardProp: (propName) => propName !== 'isActive',
})<MenuLinkProps>`
  ${typography.other.mainMenuLabel};
  text-decoration: none;
  color: ${colors.base.white};
  border-bottom: ${({ isActive }) => (isActive ? `2px solid ${colors.base.white}` : 'none')};
  &:hover {
    border-bottom: 2px solid ${colors.primary.grey};
  }
`;
