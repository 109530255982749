import styled from '@emotion/styled';
import { breakpoint, spacing } from 'component-library';

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spacing.xsmall};

  @media (max-width: ${breakpoint.medium}px) {
    flex-direction: column;
  }
`;

export const DropdownWrapper = styled.div`
  width: 100%;
  flex: 1;
`;
