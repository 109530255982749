import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../../themes';

export const ListItemWrapper = styled.div<{ disabled: boolean }>`
  white-space: nowrap;
  ${typography.other.inputLabel}
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.xmsmall};
  color: ${({ disabled }) => (disabled ? colors.primary.grey : colors.base.black)};
  background-color: ${({ disabled }) => (disabled ? colors.monochrome.grey10 : colors.base.white)};
  padding: ${spacing.xmsmall};

  &:hover {
    background-color: ${colors.background.grey};
    cursor: pointer;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const ItemWrapper = styled.div`
  min-width: ${spacing.small};
  display: flex;
`;
