const SNACKBAR_SEVERITY = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
} as const;

export type Severity = keyof typeof SNACKBAR_SEVERITY;

export type SnackbarType = {
  show: boolean;
  message: { title?: string; description?: string };
  severity: Severity;
  autoHide: boolean;
  hideDuration: number;
  small: boolean;
};

export type SnackbarPayloadType = {
  title?: string;
  description: string;
  severity: Severity;
  autoHide?: boolean;
  hideDuration?: number;
  small?: boolean;
};

export type SnackbarAction =
  | {
      type: 'show';
      payload: SnackbarPayloadType;
    }
  | { type: 'reset' | 'hide' };

export const snackbarInitialState = {
  show: false,
  message: { title: '', description: '' },
  severity: 'info',
  autoHide: true,
  hideDuration: 5000,
  small: false,
} as SnackbarType;

export const SnackbarReducer = (state: SnackbarType, action: SnackbarAction): SnackbarType => {
  switch (action.type) {
    case 'show':
      return {
        show: true,
        message: {
          title: action.payload.title,
          description: action.payload.description,
        },
        severity: action.payload.severity,
        autoHide: action.payload.autoHide || true,
        hideDuration: action.payload.hideDuration ?? snackbarInitialState.hideDuration,
        small: action.payload.small || false,
      };
    case 'hide':
      return { ...state, show: false };
    case 'reset':
      return snackbarInitialState;
    default:
      return snackbarInitialState;
  }
};
