import { createContext, useState } from 'react';

import { colors } from '../../../themes';

type AppContainerContextType = {
  backgroundColor?: string;
  setBackgroundColor: (backgroundColor: string) => void;
};

export const AppContainerContext = createContext<AppContainerContextType>({
  backgroundColor: undefined,
  setBackgroundColor: () => {
    console.warn('no AppContainerContextProvider found');
  },
});

export const AppContainerContextProvider = ({
  children,
  initialBgColor,
}: {
  children: React.ReactNode;
  initialBgColor?: string;
}) => {
  const [backgroundColor, setBackgroundColor] = useState(initialBgColor || colors.base.white);

  return (
    <AppContainerContext.Provider
      value={{
        backgroundColor,
        setBackgroundColor,
      }}
    >
      {children}
    </AppContainerContext.Provider>
  );
};
