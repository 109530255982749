import { AppContainer, colors, ErrorMessage, PageContainer, Spinner } from 'component-library';
import { FooterPlaceholder } from './FooterPlaceholder';
import { HeaderPlaceholder } from './HeaderPlaceholder';

export const LoadingI18n = ({ error }: { error?: boolean }) => {
  return (
    <AppContainer
      backgroundColor={colors.secondary.beigeTint1}
      footer={<FooterPlaceholder />}
      header={<HeaderPlaceholder />}
    >
      <PageContainer>
        {error && (
          <ErrorMessage
            title="Error loading content."
            description="Probably failed to call backend."
          />
        )}
        {!error && <Spinner size="xlarge" />}
      </PageContainer>
    </AppContainer>
  );
};
