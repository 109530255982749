import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { type ReactNode } from 'react';
import { type TransitionStatus } from 'react-transition-group';
import { breakpoint, spacing } from '../../../themes';
import { getSnackbarColors } from '../../helpers/styleHelpers';
import { type Severity } from './SnackbarReducer';

export type SnackbarWrapperProps = {
  transitionStatus: TransitionStatus;
  children: ReactNode;
};

export const SnackbarWrapper = styled.div<{
  transitionStatus: TransitionStatus;
  small: boolean;
}>`
  ${({ transitionStatus }) => showOrHideCss(transitionStatus)}
  position: fixed;
  width: 100%;
  padding: 0 ${spacing.mediumLow} ${spacing.small} ${spacing.mediumLow};
  box-sizing: border-box;
  z-index: 100;
  bottom: 0;
  left: 0;

  @media (max-width: ${breakpoint.medium}px) {
    bottom: ${spacing.xsmall};
    padding: 0 ${spacing.xsmall};
    align-items: flex-start;
  }
`;

export const SnackbarContainer = styled.div<{ severity: Severity; small: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ small }) =>
    small ? 'width: fit-content' : `max-width: ${breakpoint.xlarge}px; margin: auto`};
  padding: ${spacing.xmsmall} ${({ small }) => (small ? spacing.small : spacing.xsmall)}
    ${spacing.xmsmall} ${spacing.small};
  box-sizing: border-box;
  ${({ severity }) => severity && getSnackbarColors(severity)};
  border-radius: ${spacing.xxsmall};

  @media (max-width: ${breakpoint.medium}px) {
    align-items: flex-start;
  }
`;

export const SnackbarMessage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${spacing.xsmall};
  height: ${spacing.mediumLow};

  @media (max-width: ${breakpoint.medium}px) {
    align-items: flex-start;
    flex-direction: column;
    height: unset;
    gap: 0;
  }
`;

const showOrHideCss = (transitionStatus: TransitionStatus) => {
  if (transitionStatus === 'entering' || transitionStatus === 'entered') {
    return css`
      visibility: visible;
      opacity: 1;
      transition: opacity 0s linear;
    `;
  }
  if (transitionStatus === 'exiting') {
    return css`
      visibility: hidden;
      opacity: 0;
      transition:
        visibility 0s 0.2s,
        opacity 0.2s linear;
    `;
  }
  if (transitionStatus === 'exited') {
    return css`
      display: none;
      visibility: hidden;
      opacity: 0;
      transition:
        visibility 0s 0.2s,
        opacity 0.2s linear;
    `;
  }

  return '';
};
