import { type ReactNode, type CSSProperties, type LegacyRef, forwardRef } from 'react';
import { type Spacing } from '../../../themes';
import { ContainerWrapper } from './style';

export type ContainerProps = {
  id?: string;
  children: ReactNode;
  outline?: boolean;
  outlineColor?: string;
  square?: boolean;
  shadow?: boolean;
  backgroundColor?: string;
  p?: string | Spacing;
  style?: CSSProperties;
  hideOverflow?: boolean;
  onClick?: () => void;
  testId?: string;
};

const Container = forwardRef(
  ({ children, testId, ...rest }: ContainerProps, ref: LegacyRef<HTMLDivElement>) => {
    return (
      <ContainerWrapper ref={ref} data-testid={testId} {...rest}>
        {children}
      </ContainerWrapper>
    );
  },
);

Container.displayName = 'Container';

export { Container };
