import LogoIcon from '../../HeaderMenu/LogoIcon';
import { LogoWrapper, MenuWrapper, Nav, NavWrapper } from '../../HeaderMenu/styles';

export const HeaderPlaceholder = () => {
  return (
    <MenuWrapper>
      <NavWrapper>
        <Nav>
          <LogoWrapper data-testid="header-logo-wrapper">
            <LogoIcon />
          </LogoWrapper>
        </Nav>
      </NavWrapper>
    </MenuWrapper>
  );
};
