import styled from '@emotion/styled';
import { colors, spacing, typography } from '../themes/stena-recycling';

// Input
export const OuterLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.tiny};
`;

export const InnerLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.tiny};
`;

export const RequiredIndicator = styled.div`
  color: ${colors.primary.grey};
  ${typography.other.inputLabel}
`;

export const InputLabel = styled.label`
  ${typography.other.inputLabel}
  color: ${colors.primary.grey};
  margin-bottom: ${spacing.tiny};
  width: 100%;
`;
