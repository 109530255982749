import styled from '@emotion/styled';
import { colors, spacing, typography } from 'component-library';

type TableFooterWrapperProps = {
  noLines: boolean;
};

export const TableFooterWrapper = styled.div<TableFooterWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${({ noLines }) => (noLines ? `border-top: none;` : `1px solid ${colors.monochrome.grey20};`)}

  padding: ${spacing.small};
`;

export const PageLabel = styled.div`
  ${typography.body.large};
  color: ${colors.primary.grey};
  text-align: center;
`;

export const LoadMoreButtonWrapper = styled.div`
  display: flex;
  margin-top: ${spacing.xxsmall};
  justify-content: center;
`;

export const LabelAndButtonWrapper = styled.div`
  width: 100%;
`;
