import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colors, spacing, typography } from '../../../themes/stena-recycling';
import { type LinkProps } from '.';

type StyledLinkProps = Omit<LinkProps, 'onClick' | 'children'>;

export const StyledLink = styled.a<StyledLinkProps>`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  ${({ bold }) => (bold ? typography.body.largeBold : typography.body.large)};
  ${({ inline }) =>
    inline &&
    css`
      font-size: inherit;
    `}
  color: ${({ light: darkMode }) => (darkMode ? colors.base.white : colors.primary.blue)};
  text-decoration: none;
  text-decoration: ${({ underline, light: darkMode }) =>
    underline || darkMode ? 'underline' : 'none'};

  ${({ icon }) =>
    icon &&
    css`
      gap: ${spacing.tiny};
    `}

  &:hover {
    text-decoration: ${({ underlineOnHover }) => (underlineOnHover ? 'underline' : 'none')};
    ${({ light: darkMode }) => css`
      color: ${darkMode ? colors.base.white : colors.primary.blueShade1};
    `}
  }

  &:active {
    color: ${({ light: darkMode }) => (darkMode ? colors.base.white : colors.primary.blueShade2)};
  }

  ${({ disabled, light: darkMode, underline }) =>
    disabled &&
    css`
      pointer-events: none;
      color: ${colors.monochrome.grey50};
      text-decoration: ${underline && !darkMode ? 'underline' : 'none'};
    `}
`;

// Icons
const iconStyles = css`
  width: ${spacing.small};
  height: ${spacing.small};
  box-sizing: border-box;
`;

export const IconLeft = styled.div`
  color: inherit;
  ${iconStyles}
`;

export const IconRight = styled.div`
  color: inherit;
  ${iconStyles}
`;
