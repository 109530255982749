import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../../../../../../../themes';

export const GroupedListWrapper = styled.div<{
  nestedIndex: number;
  showAsSelected: boolean;
  inactive: boolean;
}>`
  display: flex;
  flex-flow: row;
  align-items: center;

  gap: ${spacing.xsmall};
  padding: ${spacing.xmsmall};
  ${({ nestedIndex }) => nestedIndex > 0 && `padding-left: ${12 + nestedIndex * 12}px;`}
  background-color: ${({ showAsSelected }) =>
    showAsSelected ? colors.background.grey : 'transparent'};

  &:hover {
    background-color: ${colors.background.grey};
    cursor: pointer;

    .hover-checkbox {
      opacity: ${({ inactive }) => (inactive ? 0 : 1)};
    }
  }
`;

export const StyledLabelWrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-bottom: auto;
`;

export const StyledGroupedListContent = styled.div`
  display: flex;
  flex-flow: column;
`;

export const IconWrapper = styled.div`
  display: flex;
  margin-left: auto;
  flex-shrink: 0;
  gap: ${spacing.xmsmall};
  margin-bottom: auto;
`;

export const ChevronWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
`;

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledLabel = styled.div<{ showBold: boolean; inactive: boolean }>`
  ${typography.body.large};
  ${({ showBold }) => showBold && typography.body.largeBold};
  ${({ inactive }) => inactive && `color: ${colors.primary.grey};`}
`;

export const Divider = styled.hr`
  border: 0;
  height: 1px;
  background-color: ${colors.monochrome.grey20};
  margin: 0;
`;

export const GroupLabel = styled.div`
  ${typography.body.largeBold};
  padding-top: ${spacing.xsmall};
  padding-bottom: ${spacing.tiny};
  padding-left: ${spacing.xsmall};
  padding-right: ${spacing.xsmall};
`;

export const LocationInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.xxsmall};
`;

export const LocationIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  flex-direction: column;
  width: 24px;
  padding: 4px;
  border-radius: 4px;
  background-color: ${colors.background.green};
`;
