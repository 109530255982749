import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../../../../../../../themes';

export const GroupedListItemWrapper = styled.li<{ nestedIndex?: number }>`
  ${typography.body.large};
  display: flex;
  flex-flow: column;
  ${({ nestedIndex }) =>
    nestedIndex === 0 && `border-bottom: 1px solid ${colors.monochrome.grey20};`}
`;

export const Description = styled.div`
  ${typography.body.medium};
  color: ${colors.primary.grey};
`;

export const ChildCount = styled.div`
  ${typography.body.mediumBold};
  color: ${colors.primary.grey};
`;

export const ChildWrapper = styled.div`
  display: flex;
  flex-flow: row;
  gap: ${spacing.tiny};
`;
