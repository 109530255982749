import styled from '@emotion/styled';
import { breakpoint, spacing } from '../../../themes';

export const PageContainerModalWrap = styled.div`
  margin-right: var(--active-modal-padding);
`;

export const PageContainerWrapper = styled.div`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: ${spacing.mediumHigh} 0 ${spacing.xxhuge} 0;
  width: 100%;

  @media (max-width: ${breakpoint.xlarge + 96}px) {
    padding: ${spacing.mediumHigh} ${spacing.mediumHigh} ${spacing.xxhuge} ${spacing.mediumHigh};
  }

  @media (max-width: ${breakpoint.large}px) {
    padding: ${spacing.mediumHigh} ${spacing.mediumHigh} ${spacing.xlarge} ${spacing.mediumHigh};
  }

  @media (max-width: ${breakpoint.small}px) {
    padding: ${spacing.mediumLow} ${spacing.small} ${spacing.mediumHigh} ${spacing.small};
  }
`;
