import styled from '@emotion/styled';
import { breakpoint, colors, spacing } from '../../../themes';

const getFlexDirection = (imageDirection: string) => {
  switch (imageDirection) {
    case 'left':
      return 'row';
    case 'right':
      return 'row-reverse';
  }
};

export const MediaCardWrapper = styled.div<{
  mt?: string;
  mb?: string;
  imageDirection: string;
  boxed: boolean;
}>`
  display: flex;
  gap: ${spacing.mediumLow};
  flex-direction: ${(props) => getFlexDirection(props.imageDirection)};
  border-radius: ${spacing.xxsmall};
  background: ${(props) => props.boxed && colors.base.white};
  min-height: 316px;
  ${(props) => props.mt && `margin-top: ${props.mt}`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}`};

  @media (max-width: ${breakpoint.medium}px) {
    flex-direction: column;
    gap: 0;
  }
`;

const getBorderRadius = (boxed: boolean, imageDirection: string) => {
  if (boxed) {
    if (imageDirection === 'left') {
      return 'border-top-right-radius: 0px; border-bottom-right-radius: 0px;';
    } else {
      return 'border-top-left-radius: 0px; border-bottom-left-radius: 0px;';
    }
  } else {
    return 'border-radius: inherit;';
  }
};

export const Media = styled.div<{
  image: string;
  imageDirection: string;
  boxed: boolean;
  backgroundPosition?: string;
  fitToContent?: boolean;
}>`
  display: flex;
  flex: 1;
  background-color: ${colors.primary.blue};
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: ${(props) => props.backgroundPosition};
  border-radius: inherit;
  align-self: ${(props) => (props.boxed ? 'stretch' : 'flex-start')};

  aspect-ratio: ${(props) => props.fitToContent && '3/2'};
  @media (max-width: ${breakpoint.medium}px) {
    aspect-ratio: 3/2;
  }

  ${(props) => getBorderRadius(props.boxed, props.imageDirection)}

  @media (max-width: ${breakpoint.medium}px) {
    ${(props) =>
      props.boxed &&
      `border-top-left-radius: inherit; 
        border-top-right-radius: inherit; 
        border-bottom-left-radius: 0px; 
        border-bottom-right-radius: 0px;`}

    ${(props) => !props.boxed && 'align-self: stretch;'}
  }
`;

const getPaddingLeft = (boxed: boolean, imageDirection: string) => {
  if (boxed) {
    if (imageDirection === 'left') {
      return spacing.small;
    } else {
      return spacing.mediumLow;
    }
  } else {
    if (imageDirection === 'left') {
      return spacing.small;
    } else {
      return '0px';
    }
  }
};

const getPaddingRight = (boxed: boolean, imageDirection: string) => {
  if (boxed) {
    if (imageDirection === 'left') {
      return spacing.mediumLow;
    } else {
      return spacing.small;
    }
  } else {
    if (imageDirection === 'left') {
      return '0px';
    } else {
      return spacing.small;
    }
  }
};

export const Content = styled.div<{ boxed: boolean; imageDirection: string }>`
  flex: 1;
  padding-top: ${(props) => (props.boxed ? spacing.mediumHigh : '0px')};
  padding-bottom: ${(props) => (props.boxed ? spacing.mediumHigh : '0px')};
  padding-left: ${(props) => getPaddingLeft(props.boxed, props.imageDirection)};
  padding-right: ${(props) => getPaddingRight(props.boxed, props.imageDirection)};

  @media (max-width: ${breakpoint.medium}px) {
    padding: ${spacing.mediumHigh} ${spacing.mediumLow};

    padding-left: ${(props) => !props.boxed && '0'};
    padding-right: ${(props) => !props.boxed && '0'};
  }

  @media (max-width: ${breakpoint.small}px) {
    padding: ${spacing.mediumLow} ${spacing.small};

    padding-left: ${(props) => !props.boxed && '0'};
    padding-right: ${(props) => !props.boxed && '0'};
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: ${spacing.mediumLow};

  @media (max-width: ${breakpoint.small}px) {
    button {
      width: 100%;
    }
  }
`;
