import styled from '@emotion/styled';
import { breakpoint, spacing } from '../../../themes';

export const OrderSummaryModalWrapper = styled.div`
  margin-right: var(--active-modal-padding);
  flex: 1;
  display: flex;
  justify-content: center;
`;

export const OrderSummaryContainerWrapper = styled.div<{ pt?: string; pb?: string }>`
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${breakpoint.xlarge + 96}px) {
    padding-left: ${spacing.mediumHigh};
  }

  @media (max-width: ${breakpoint.medium}px) {
    padding-left: unset;
  }
`;
