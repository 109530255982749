import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../../../../../themes';
import { hoverCheckboxStyle } from '../../../../styles';

export const GroupedListWrapper = styled.div<{ nestedIndex: number }>`
  display: flex;
  flex-flow: row;
  align-items: center;

  gap: ${spacing.xsmall};
  padding: ${spacing.xmsmall};
  ${({ nestedIndex }) => nestedIndex > 0 && `padding-left: ${12 + nestedIndex * 12}px;`}

  &:hover {
    background-color: ${colors.background.grey};
    cursor: pointer;
  }

  ${hoverCheckboxStyle}
`;

export const GroupedListContent = styled.div`
  display: flex;
  flex-flow: column;
`;

export const StyledCheckboxWrapper = styled.div`
  margin-bottom: auto;
`;

export const ChevronWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  margin-left: auto;
`;

export const Label = styled.div<{ showBold: boolean }>`
  ${typography.body.large};
  ${({ showBold }) => showBold && typography.body.largeBold};
`;

export const Divider = styled.hr`
  border: 0;
  height: 1px;
  background-color: ${colors.monochrome.grey20};
  margin: 0;
`;

export const GroupLabel = styled.div`
  ${typography.body.largeBold};
  padding-top: ${spacing.xsmall};
  padding-bottom: ${spacing.tiny};
  padding-left: ${spacing.xsmall};
  padding-right: ${spacing.xsmall};
`;
