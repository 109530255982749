// We do this check in the backend but does not send that data to the frontend. We should get this data from the backend instead of doing the check here.
export const checkIfInternalUser = (email?: string | null): boolean => {
  const domain = email?.split('@')[1];

  switch (domain) {
    case 'stenaaluminium.com':
      return true;
    case 'stenaaluminium.se':
      return true;
    case 'stenacomponents.se':
      return true;
    case 'stenaconfidential.com':
      return true;
    case 'stenaconfidential.dk':
      return true;
    case 'stenaconfidential.fi':
      return true;
    case 'stenaconfidential.no':
      return true;
    case 'stenaconfidential.se':
      return true;
    case 'stenametal.com':
      return true;
    case 'stenametalinternational.com':
      return true;
    case 'stenametall.com':
      return true;
    case 'stenametall.de':
      return true;
    case 'stenametall.it':
      return true;
    case 'stenametall.se':
      return true;
    case 'stenametallbc.com':
      return true;
    case 'stenaoil.com':
      return true;
    case 'stenarecycling.com':
      return true;
    case 'stenarecycling.fi':
      return true;
    case 'stenarecycling.no':
      return true;
    case 'stenarecycling.se':
      return true;
    case 'stenastal.se':
      return true;
    default:
      return false;
  }
};
