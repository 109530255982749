import {
  type ReactNode,
  type MouseEvent,
  forwardRef,
  useRef,
  useState,
  type ForwardedRef,
} from 'react';
import { ButtonSpinner, IconLeft, IconRight, Label, StyledButton, StyledLink } from './style';
import { type LinkProps } from 'react-router-dom';

export type ButtonProps = {
  children?: ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'text-primary' | 'text-secondary';
  severity?: 'danger' | 'warning' | 'info' | 'success';
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  icon?: ReactNode;
  iconPlacement?: 'before' | 'after';
  fullWidth?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  id?: string;
  testId?: string;
  preventDoubleClick?: boolean;
};

function ButtonComponent(
  {
    children,
    variant,
    severity,
    icon,
    iconPlacement = 'before',
    fullWidth,
    disabled,
    isLoading,
    id,
    preventDoubleClick,
    testId,
    onClick,
    ...rest
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const _ref = useRef<HTMLButtonElement>(null);

  const [disabledFromClick, setDisabledFromClick] = useState(false);

  const onBtnClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (preventDoubleClick) {
      setDisabledFromClick(true);
      setTimeout(() => {
        if (!disabled) {
          setDisabledFromClick(false);
        } else {
          setDisabledFromClick(true);
        }
      }, 100);
    }
    if (!isLoading && onClick) {
      onClick(e);
    }
  };

  const content = (
    <>
      {!isLoading && icon && iconPlacement === 'before' && <IconLeft>{icon}</IconLeft>}
      {children && (
        <Label
          variant={variant}
          severity={severity}
          icon={!!icon}
          iconPlacement={iconPlacement}
          isLoading={isLoading}
        >
          {children}
        </Label>
      )}
      {isLoading && <ButtonSpinner color="currentColor" />}
      {!isLoading && icon && iconPlacement === 'after' && <IconRight>{icon}</IconRight>}
    </>
  );

  return (
    <StyledButton
      id={id}
      ref={ref ?? _ref}
      data-testid={testId ?? 'button'}
      onClick={onBtnClick}
      variant={variant ?? 'primary'}
      severity={severity}
      fullWidth={fullWidth}
      disabled={disabledFromClick || disabled}
      isLoading={isLoading}
      hasIcon={!!icon}
      {...rest}
    >
      {content}
    </StyledButton>
  );
}

function LinkButtonComponent(
  {
    children,
    variant,
    severity,
    icon,
    iconPlacement = 'before',
    fullWidth,
    disabled,
    isLoading,
    id,
    preventDoubleClick,
    testId,
    onClick,
    ...rest
  }: ButtonProps & LinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  const _ref = useRef<HTMLAnchorElement>(null);

  const [disabledFromClick, setDisabledFromClick] = useState(false);

  const onBtnClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (preventDoubleClick) {
      setDisabledFromClick(true);
      setTimeout(() => {
        if (!disabled) {
          setDisabledFromClick(false);
        } else {
          setDisabledFromClick(true);
        }
      }, 100);
    }
    if (!isLoading && onClick) {
      onClick(e);
    }
  };

  const content = (
    <>
      {!isLoading && icon && iconPlacement === 'before' && <IconLeft>{icon}</IconLeft>}
      {children && (
        <Label
          variant={variant}
          severity={severity}
          icon={!!icon}
          iconPlacement={iconPlacement}
          isLoading={isLoading}
        >
          {children}
        </Label>
      )}
      {isLoading && <ButtonSpinner color="currentColor" />}
      {!isLoading && icon && iconPlacement === 'after' && <IconRight>{icon}</IconRight>}
    </>
  );

  return (
    <StyledLink
      id={id}
      ref={ref ?? _ref}
      data-testid={testId ? testId : 'button'}
      onClick={onBtnClick}
      variant={variant ?? 'primary'}
      severity={severity}
      fullWidth={fullWidth}
      disabled={disabledFromClick || disabled}
      isLoading={isLoading}
      hasIcon={!!icon}
      {...rest}
    >
      {content}
    </StyledLink>
  );
}

ButtonComponent.displayName = 'Button';
LinkButtonComponent.displayName = 'LinkButton';

function isLink(props: (ButtonProps & LinkProps) | ButtonProps): props is ButtonProps & LinkProps {
  return 'to' in props;
}

function ButtonComponentWrapper(
  props: ButtonProps | (ButtonProps & LinkProps),
  ref: ForwardedRef<HTMLAnchorElement | HTMLButtonElement>,
) {
  if (isLink(props)) {
    return LinkButtonComponent(props, ref as ForwardedRef<HTMLAnchorElement>);
  } else {
    return ButtonComponent(props, ref as ForwardedRef<HTMLButtonElement>);
  }
}

export const Button = forwardRef(ButtonComponentWrapper);
