const primary = {
  blue: '#327EA5',
  blueTint1: '#5199BB',
  blueTint2: '#95CEE9',
  blueTint3: '#DAF1FC',
  blueShade1: '#155D7F',
  blueShade2: '#003F61',
  blueShade3: '#002143',
  grey: '#62636A',
};

const secondary = {
  green: '#657B6C',
  greenTint1: '#E5EBE6',
  red: '#A46351',
  redTint1: '#EBDAD6',
  blue: '#5A6C81',
  blueTint1: '#E5EAF0',
  beige: '#C5BAAC',
  beigeTint1: '#F2F1ED',
};

const alerts = {
  success: '#038848',
  successTint1: '#7DB386',
  successTint2: '#B1E2B4',
  successTint3: '#DFF6E0',
  successShade1: '#02753E',
  successShade2: '#035D31',
  successShade3: '#014B28',
  info: '#2374E1',
  infoTint1: '#5795E8',
  infoTint2: '#A3C5F3',
  infoTint3: '#EAF2FC',
  infoShade1: '#1D6AD1',
  infoShade2: '#1758AE',
  infoShade3: '#15498E',
  warning: '#FCCD2C',
  warningTint1: '#FFDE6C',
  warningTint2: '#FFE899',
  warningTint3: '#FFF0BD',
  warningShade1: '#EABE29',
  warningShade2: '#B6931E',
  warningShade3: '#6E5910',
  error: '#DA1E28',
  errorTint1: '#EC777D',
  errorTint2: '#F3BABD',
  errorTint3: '#FAE2E3',
  errorShade1: '#C81B25',
  errorShade2: '#A3151C',
  errorShade3: '#710D12',
};

const base = {
  black: '#1E1E1E',
  white: '#FFFFFF',
};

const monochrome = {
  grey10: '#EFEFEF',
  grey20: '#E6E6E6',
  grey30: '#D6D7D9',
  grey40: '#B7B7B7',
  grey50: '#8E8E8E',
  grey60: '#787878',
  grey70: '#595959',
  grey80: '#424242',
  grey90: '#2E2E2E',
};

const background = {
  green: '#E5EBE6',
  red: '#EBDAD6',
  blue: '#E5EAF0',
  beige: '#F2F1ED',
  grey: '#F8F8F8',
};

export const colors = {
  primary,
  secondary,
  alerts,
  base,
  background,
  monochrome,
};
