import { useTranslation } from 'react-i18next';
import { type LegacyRef, forwardRef, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Calendar } from '../../../../../Icons';
import { IconButton } from '../../../../IconButton';
import { stripNonNumericCharacters } from '../../../helpers/dateInput';
import { Textfield } from '../../../../Text/Textfield';
import { spacing } from '../../../../../../themes';
import { ButtonWrapper, InputWrapper } from './styles';

type DateRangeType = {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
};

type CustomRangeInputProps = {
  value?: string;
  toggleOpen?: () => void;
  updateRange?: (dates: [Date | null, Date | null]) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
  disabled?: boolean;
};

export const CustomRangeInput = forwardRef(
  (
    { value, toggleOpen, updateRange, minDate, maxDate, disabled }: CustomRangeInputProps,
    ref: LegacyRef<HTMLDivElement>,
  ) => {
    const { t } = useTranslation();
    const [input, setInput] = useState<string>(value ? value : '');
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInput(e.target.value);
    };

    const handleBlur = () => {
      let dates = getDayJSDates(input);

      if (!dates.startDate.isValid() && value) {
        dates = getDayJSDates(value);
      }

      formatOutput(dates);

      if (isDatesValid(dates) && updateRange) {
        updateRange([dates.startDate.toDate(), dates.endDate.toDate()]);
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleBlur();
      }
    };

    const getDayJSDates = (input: string): DateRangeType => {
      const rawInput = stripNonNumericCharacters(input);

      const startDate = dayjs(rawInput.substring(0, 8), 'YYYYMMDD', true);
      const endDate = dayjs(rawInput.substring(8, 16), 'YYYYMMDD', true);

      return {
        startDate: startDate,
        endDate: endDate,
      } as DateRangeType;
    };

    const formatOutput = (dates: DateRangeType) => {
      // Construct the output
      let output = dayjs(dates.startDate).format('YYYY-MM-DD');

      // Only add end date to output if it has a value
      if (dayjs(dates.endDate).isValid()) {
        output += ` → ${dayjs(dates.endDate).format('YYYY-MM-DD')}`;
      }

      setInput(output);
    };

    const isDatesValid = (dates: DateRangeType): boolean => {
      const { startDate, endDate } = dates;

      // min - max dates
      if (minDate) {
        if (startDate.isBefore(minDate, 'day')) {
          setErrorMessage(
            t('datepicker.error.startDateBeforeMinDate', {
              minDate: dayjs(minDate).format('YYYY-MM-DD'),
            }),
          );

          return false;
        }
      }

      if (maxDate) {
        if (startDate.isAfter(maxDate, 'day')) {
          setErrorMessage(
            t('datepicker.error.startDateAfterMaxDate', {
              maxDate: dayjs(maxDate).format('YYYY-MM-DD'),
            }),
          );

          return false;
        }

        if (endDate.isAfter(maxDate, 'day')) {
          setErrorMessage(
            t('datepicker.error.endDateAfterMaxDate', {
              maxDate: dayjs(maxDate).format('YYYY-MM-DD'),
            }),
          );

          return false;
        }
      }

      if (endDate.isValid()) {
        if (endDate.isBefore(startDate)) {
          setErrorMessage(t('datepicker.error.endDateBeforeStartDate'));

          return false;
        }

        if (endDate.isSame(startDate)) {
          setErrorMessage(t('datepicker.error.sameDate'));

          return false;
        }
      }

      if (!endDate.isValid()) {
        setErrorMessage(t('datepicker.error.noDate'));

        return false;
      }

      setErrorMessage(undefined);

      return true;
    };

    useEffect(() => {
      if (value) {
        const dates = getDayJSDates(value);

        formatOutput(dates);
      }
    }, [value]);

    return (
      <InputWrapper ref={ref}>
        <Textfield
          data-testid="custom-range-input"
          style={{ paddingRight: spacing.mediumHigh }}
          value={input}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={errorMessage}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
        <ButtonWrapper>
          <IconButton
            data-testid="toggle-datepicker"
            onClick={toggleOpen}
            variant="secondary"
            disabled={disabled}
          >
            <Calendar />
          </IconButton>
        </ButtonWrapper>
      </InputWrapper>
    );
  },
);

CustomRangeInput.displayName = 'CustomRangeInput';
