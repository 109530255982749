import { useCallback, useSyncExternalStore } from 'react';

type Listener = () => void;
type StateUpdater<S> = (prevState: S) => S;
type Subscribe = (listener: Listener) => () => void;

type Store<S> = {
  getSnapshot: () => S; // Function to get the current state
  resetSnapshot: () => void; // Function to set the snapshot to the initial snapshot
  setSnapshot: (fn: StateUpdater<S>) => void; // Function to update the state
  subscribe: Subscribe; // Function to subscribe to state changes
};

// Function to create a store with an initial state
const createStateStore = <S>(initialSnapshot: S): Store<S> => {
  let snapshot = { ...initialSnapshot }; // Initialize the state
  const listeners = new Set<Listener>(); // Initialize the listeners set

  const getSnapshot: Store<S>['getSnapshot'] = () => snapshot;

  const setSnapshot: Store<S>['setSnapshot'] = (fn) => {
    snapshot = fn(snapshot); // Update the state

    // Notify all listeners about the state change
    listeners.forEach((listener) => {
      listener();
    });
  };

  const subscribe: Store<S>['subscribe'] = (listener) => {
    listeners.add(listener); // Add the listener to the set

    // Return a function to unsubscribe the listener
    return () => listeners.delete(listener);
  };

  const resetSnapshot: () => void = () => {
    snapshot = { ...initialSnapshot }; // Reset the state

    // Notify all listeners about the state change
    listeners.forEach((listener) => {
      listener();
    });
  };

  return { getSnapshot, setSnapshot, subscribe, resetSnapshot };
};

// Function to use a store with a selector function
const useSelectedStoreState = <S, Selected>(
  store: Store<S>,
  selector: (snapshot: S) => Selected,
): Selected => {
  return useSyncExternalStore(
    store.subscribe,
    useCallback(() => selector(store.getSnapshot()), [store, selector]),
    () => selector(store.getSnapshot()), // Server-side rendering support
  );
};

const isFunction = <T>(value: T): value is T & ((...args: never[]) => unknown) => {
  return typeof value === 'function';
};

// Extract only the setter properties (function properties)
type ExtractSettersProps<T> = {
  [K in keyof T]: T[K] extends (...args: never[]) => unknown ? K : never;
}[keyof T];

export const createStore = <T extends object>(initialSnapshot: T) => {
  type SettersKeys = ExtractSettersProps<T>;

  type State = Omit<T, SettersKeys>; // Extract state properties
  type Setters = Pick<T, SettersKeys>; // Extract setter properties

  const stateSnapshot: Partial<State> = {};
  const setters: Partial<Setters> = {};

  for (const key in initialSnapshot) {
    const value = initialSnapshot[key];

    if (isFunction(value)) {
      setters[key as unknown as keyof Setters] = value as Setters[keyof Setters];
    } else {
      stateSnapshot[key as unknown as keyof State] = value as unknown as State[keyof State];
    }
  }

  const store = createStateStore(stateSnapshot as State);

  const useStoreState = <Selected>(selector: (snapshot: State) => Selected): Selected =>
    useSelectedStoreState(store, selector);

  // Overload signatures for setState
  function setState<K extends keyof State>(field: K, value: State[K]): void;
  function setState(fn: (state: State) => State): void;

  // Implementation of setState that handles both setting single field and multiple fields
  function setState<K extends keyof State>(arg1: K | ((state: State) => State), arg2?: State[K]) {
    if (typeof arg1 === 'function') {
      store.setSnapshot(arg1 as (state: State) => State);
    } else {
      store.setSnapshot((snapshot) => ({ ...snapshot, [arg1]: arg2 }));
    }
  }

  const resetState = () => {
    store.resetSnapshot();
  };

  return {
    ...setters,
    useStoreState,
    setState,
    resetState,
  } as Omit<Setters, never> & {
    useStoreState: <Selected>(selector: (snapshot: State) => Selected) => Selected;
    setState: typeof setState;
    resetState: typeof resetState;
  };
};
