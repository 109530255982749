import styled from '@emotion/styled';
import { breakpoint, colors, spacing } from 'component-library';
import { maxmq } from './../assets/styles/breakpoints';

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: ${colors.base.black};
`;

export const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${colors.base.black};
  padding-right: var(--active-modal-padding);
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${spacing.xxlarge};
  box-sizing: border-box;
  line-height: 1;
  max-width: ${breakpoint.xlarge}px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${breakpoint.xlarge + 96}px) {
    padding-left: ${spacing.mediumHigh};
    padding-right: ${spacing.mediumHigh};
  }

  @media (max-width: ${breakpoint.small}px) {
    padding-left: ${spacing.small};
    padding-right: ${spacing.small};
  }
`;

export const SubNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${colors.base.white};
  border-bottom: 1px solid ${colors.monochrome.grey10};
  padding-right: var(--active-modal-padding);

  ${maxmq[2]} {
    display: none;
  }
`;
export const SubNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;

  height: ${spacing.large};
  max-width: ${breakpoint.xlarge}px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${breakpoint.xlarge + 96}px) {
    padding-left: ${spacing.mediumHigh};
    padding-right: ${spacing.mediumHigh};
  }

  @media (max-width: ${breakpoint.small}px) {
    padding-left: ${spacing.small};
    padding-right: ${spacing.small};
  }
`;
export const AnnounceKitWrapper = styled.div`
  margin-right: ${spacing.xsmall};
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: ${spacing.xsmall};
`;
