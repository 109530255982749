export const breakpoint = {
  xlarge: 1440,
  large: 1024,
  medium: 768,
  small: 430,
};

export const breakpointsAsArray = [
  breakpoint.small,
  breakpoint.medium,
  breakpoint.large,
  breakpoint.xlarge,
];
