import { IconWrapper } from './style';
import type { IconProps } from '../../types';

export const ExternalLink = ({
  color,
  rotation,
  width = '24px',
  height = '24px',
  cursor,
}: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ?? 0})`}
    >
      <path
        clipRule="evenodd"
        d="m20.774 3.22595c.0758.07572.1325.16328.1701.25672l.0554.26244c.0003.00874.0005.01751.0005.02632v.00298 5.13988c0 .42604-.3454.77142-.7714.77142-.4261 0-.7715-.34538-.7715-.77142v-3.28046l-8.9688 8.96877c-.3012.3013-.78967.3013-1.09093 0-.30126-.3012-.30126-.7897 0-1.0909l8.96883-8.96884h-3.2805c-.426 0-.7714-.34538-.7714-.77143s.3454-.77143.7714-.77143h5.1428.0001c.1974 0 .3948.07531.5454.22595zm-14.94543 2.34548c-1.56218 0-2.82857 1.26639-2.82857 2.82857v9.7714c0 1.5622 1.26639 2.8286 2.82857 2.8286h9.77143c1.5622 0 2.8286-1.2664 2.8286-2.8286v-5.1428c0-.4261-.3454-.7715-.7715-.7715-.426 0-.7714.3454-.7714.7715v5.1428c0 .7101-.5756 1.2857-1.2857 1.2857h-9.77143c-.71008 0-1.28571-.5756-1.28571-1.2857v-9.7714c0-.71008.57563-1.28571 1.28571-1.28571h6.17143c.426 0 .7714-.34538.7714-.77143s-.3454-.77143-.7714-.77143z"
        fill={color ?? 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  </IconWrapper>
);
