import { css } from '@emotion/react';
import { colors, spacing, typography } from '../../themes';
import { type InputType } from '../Input/Text/Textfield/types';

export const getEmailPattern = () => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
};

export const checkNumberRange = ({
  value,
  max,
  min,
}: {
  value: string;
  max?: number;
  min?: number;
}) => {
  if (value === '') value = '0';

  const numberTooHigh = max !== undefined ? Number(value) > Number(max) : false;
  const numberTooLow = min !== undefined ? Number(value) < Number(min) : false;
  const numberIsInRange = !numberTooHigh && !numberTooLow;

  let _numberToUse = value;

  if (numberTooHigh && max !== undefined) {
    _numberToUse = `${max}`;
  }

  if (numberTooLow && min !== undefined) {
    _numberToUse = `${min}`;
  }

  return {
    numberIsInRange,
    numberTooHigh,
    numberTooLow,
    numberToUse: `${Number(_numberToUse)}`,
  };
};

export const inputBorderStyle = (error: boolean) => {
  return css`
    box-sizing: border-box;
    border: 1px solid ${error ? colors.alerts.error : colors.monochrome.grey40};
    border-radius: 4px;
    padding: ${spacing.xmsmall} ${spacing.xmsmall};
  `;
};

export const inputFieldStyles = (error: boolean) => {
  return css`
    font-family: ${typography.fontFamily};
    color: ${colors.base.black};
    ${typography.other.inputValue}
    width: 100%;

    &:disabled {
      color: ${colors.monochrome.grey50};
      background-color: ${colors.monochrome.grey10};
      border: 1px solid ${colors.monochrome.grey40};
      cursor: not-allowed;
    }

    &::placeholder {
      color: ${colors.monochrome.grey60};
    }

    &:hover {
      border: 1px solid ${error ? colors.alerts.error : colors.primary.grey};
      &:disabled {
        border: 1px solid ${colors.monochrome.grey40};
      }
    }

    &:focus {
      outline: none;
      color: ${colors.base.black};
      border: 2px solid ${error ? colors.alerts.error : colors.primary.grey};
    }

    &::-ms-reveal {
      display: none;
    }
  `;
};

export const getInputTypeStyles = (type: InputType) => {
  if (type === 'password') {
    return css`
      padding-right: ${spacing.mediumHigh};
    `;
  }

  return;
};

export const getIconColor = (disabled: boolean, isFocused: boolean) => {
  if (disabled) {
    return colors.monochrome.grey50;
  }

  if (isFocused) {
    return colors.base.black;
  }

  return colors.primary.grey;
};

export const handleMaxLength = (value: string, maxLength: number) => {
  if (value.length > maxLength) {
    return value.slice(0, maxLength);
  }

  return value;
};

export const getPasswordPattern = () => {
  // Minimum eight characters,
  // at least one uppercase letter,
  // one lowercase letter and one number
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
};
