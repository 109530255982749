import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../../themes/stena-recycling';
import { inputBorderStyle, inputFieldStyles } from '../../../helpers/inputHelpers';

export const SearchfieldWrapper = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputWrapper = styled.div`
  position: relative;
  height: ${spacing.mediumHigh};
`;

export const SearchIconWrapper = styled.div`
  position: absolute;
  width: ${spacing.small};
  height: ${spacing.small};
  padding: 12px;
  left: 0;
  box-sizing: content-box;
`;

export const IconButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: ${spacing.xxsmall};
`;

export const StyledSearchfield = styled.input<{ error: boolean }>`
  ${({ error }) => inputBorderStyle(error)}
  ${({ error }) => inputFieldStyles(error)}
  text-overflow: ellipsis;
  height: ${spacing.mediumHigh};
  padding-left: ${spacing.mediumHigh};
  padding-right: ${spacing.mediumHigh};

  &:focus {
    padding-left: calc(${spacing.mediumHigh} - 1px);
    padding-right: calc(${spacing.mediumHigh} - 1px);
  }

  /* clears the 'X' from search input */
  &[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  &[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    display: none;
  }
`;

export const SearchfieldError = styled.span`
  ${typography.body.medium}
  letter-spacing: 0.5px;
  color: ${colors.alerts.error};
`;
