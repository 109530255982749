import styled from '@emotion/styled';
import { colors } from '../../themes';
import { type PictogramCircleProps } from './Pictograms/types';

export const CircleWrapper = styled.div<Omit<PictogramCircleProps, 'pictogram'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  color: ${({ variant }) => getColor(variant)};
`;

export const PictogramIconWrapper = styled.div<Pick<PictogramCircleProps, 'variant'>>`
  position: absolute;
  width: 38.78%;
  height: 38.78%;
  color: ${({ variant }) => getIconColor(variant)};
`;

export const getColor = (variant: PictogramCircleProps['variant']) => {
  switch (variant) {
    case 'darkBlue':
      return colors.secondary.blue;
    case 'darkGreen':
      return colors.secondary.green;
    case 'darkRed':
      return colors.secondary.red;
    case 'blue':
      return colors.secondary.blueTint1;
    case 'green':
      return colors.secondary.greenTint1;
    case 'red':
      return colors.secondary.redTint1;
    case 'grey':
    default:
      return colors.monochrome.grey10;
  }
};

const getIconColor = (variant: PictogramCircleProps['variant']) => {
  switch (variant) {
    case 'blue':
      return colors.secondary.blue;
    case 'green':
      return colors.secondary.green;
    case 'red':
      return colors.secondary.red;
    case 'grey':
    default:
      return colors.primary.grey;
  }
};
