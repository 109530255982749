import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../themes/stena-recycling';

export const CheckboxContainer = styled.div`
  // font-family: inherit;
  display: flex;
  flex-direction: row;
`;

export const HoverCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0;
  justify-content: center;
  cursor: pointer;

  ${CheckboxContainer}:hover & {
    opacity: 1;
  }
`;

export const StyledCheckbox = styled.button<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid ${({ disabled }) => (disabled ? colors.monochrome.grey40 : colors.base.black)};
  padding: 0;
  width: ${spacing.small};
  height: ${spacing.small};
  background: ${colors.base.white};
  border-radius: 3px;

  :disabled {
    cursor: unset;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${spacing.xmsmall};
`;
export const OnClickElement = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
`;
export const Label = styled.label`
  cursor: pointer;
  ${typography.other.inputLabel}
`;
export const LabelDiv = styled.div`
  ${typography.other.inputLabel}
`;

export const Description = styled.span`
  ${typography.body.medium}
  color: ${colors.primary.grey};
`;

export const StyledInput = styled.input`
  // Hide the input element without hiding it from screen readers
  display: block;
  width: 0;
  height: 0;
  margin: 0;
  scale: 0;
`;
