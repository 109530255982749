import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../themes/stena-recycling';
import { type LabelProps } from '.';

export const StyledLabel = styled.div<LabelProps>`
  font-family: inherit;
  ${typography.body.medium};
  display: inline-flex;
  padding: 0 ${spacing.xxsmall};
  border-radius: 2px;

  ${({ variant }) => {
    switch (variant) {
      case 'warning':
        return css`
          background-color: ${colors.alerts.warningTint3};
          color: ${colors.alerts.warningShade3};
          border: 1px solid ${colors.alerts.warningTint1};
        `;
      case 'error':
        return css`
          background-color: ${colors.alerts.errorTint3};
          color: ${colors.alerts.errorShade2};
          border: 1px solid ${colors.alerts.errorTint2};
        `;
      case 'success':
        return css`
          background-color: ${colors.alerts.successTint3};
          color: ${colors.alerts.successShade2};
          border: 1px solid ${colors.alerts.successTint2};
        `;
      case 'info':
        return css`
          background-color: ${colors.alerts.infoTint3};
          color: ${colors.primary.blueShade1};
          border: 1px solid ${colors.alerts.infoTint2};
        `;
      case 'neutral':
      default:
        return css`
          background-color: ${colors.monochrome.grey20};
          color: ${colors.monochrome.grey70};
          border: 1px solid ${colors.monochrome.grey40};
        `;
    }
  }}
`;
