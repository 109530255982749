import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../../../themes';

export const StyledSegmentedButton = styled.div<{ active: boolean }>`
  ${typography.other.inputLabel};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) => (active ? colors.secondary.blue : colors.base.white)};
  color: ${({ active }) => (active ? colors.base.white : colors.secondary.blue)};
  padding: ${spacing.xmsmall} ${spacing.small};
  user-select: none;
  border-right: 1px solid ${colors.monochrome.grey40};

  &:hover {
    cursor: ${({ active }) => (active ? 'default' : 'pointer')};
    background-color: ${({ active }) =>
      active ? colors.secondary.blue : colors.monochrome.grey20};
    color: ${({ active }) => (active ? colors.base.white : colors.secondary.blue)};
  }

  &:last-of-type {
    border-right: unset;
  }
`;
