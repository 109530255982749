import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../themes';

export const GroupContainer = styled.div<{ inline?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  ${({ inline }) =>
    inline &&
    `
    flex-direction: row;
    align-items: stretch;
    gap: ${spacing.small};
  `};
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xmsmall};
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GroupLabel = styled.div<{ inline?: boolean; wrapLabel?: boolean }>`
  ${typography.other.inputLabel}
  color: ${colors.primary.grey};
  ${({ inline }) => {
    if (inline) {
      return `
            display: inline-flex;
            align-items: center;
            flex: 1;
            margin-right: ${spacing.small};
  `;
    } else {
      return `
              display: block;
              margin-bottom: ${spacing.xsmall};
  `;
    }
  }};
  ${({ wrapLabel }) => wrapLabel === false && `white-space: nowrap;`};
`;

export const RequiredIndicator = styled.div`
  display: inline-block;
  color: ${colors.primary.grey};
  ${typography.other.inputLabel}
`;

export const Label = styled.label<{ disabled?: boolean }>`
  ${typography.other.inputLabel}
  padding-left: ${spacing.xxsmall};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const Description = styled.span`
  ${typography.body.medium}
  padding-left: ${spacing.xxsmall};
  color: ${colors.primary.grey};
`;

export const StyledRadioButton = styled.input`
  cursor: pointer;
  padding: ${spacing.tiny};
  box-sizing: border-box;
  appearance: none;
  margin: 0;
  width: ${spacing.small};
  min-width: ${spacing.small};
  height: ${spacing.small};
  border: 2px solid ${colors.base.black};
  border-radius: 50%;

  ::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-color: ${colors.base.white};
  }

  &:hover:not(:disabled):not(:checked) {
    ::after {
      background-color: ${colors.monochrome.grey40};
    }
  }

  &:checked {
    ::after {
      background-color: ${colors.base.black};
    }
  }

  &:disabled {
    cursor: default;
    border-color: ${colors.monochrome.grey40};

    &:checked {
      ::after {
        background-color: ${colors.monochrome.grey40};
      }
    }

    & + div label {
      cursor: default;
      color: ${colors.monochrome.grey40};
    }
  }
`;

export const OptionsWrapper = styled.div<{ direction: 'column' | 'row'; hasError?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ direction }) => direction};

  gap: ${({ direction }) => (direction === 'column' ? spacing.xsmall : spacing.mediumLow)};
  ${({ hasError }) => hasError && `border: 1px solid ${colors.alerts.error}; padding: 12px;`};
`;

export const ErrorWrapper = styled.div`
  height: 22px;
  ${typography.body.medium}
  color: ${colors.alerts.error};
  margin-top: ${spacing.tiny};
`;
