import { useContext } from 'react';
import { ProfileContext } from './ProfileProvider';

/**
 * @deprecated Use useProfileV2 instead
 */
export const useProfile = () => {
  const context = useContext(ProfileContext);

  if (!context) {
    throw Error('Wrap your app with `ProfileProvider`');
  } else {
    return [
      context.profile,
      context.loading,
      context.isAuthenticated,
      context.updateProfile,
    ] as const;
  }
};
