import styled from '@emotion/styled';
import { breakpoint, colors, spacing } from '../../../themes';

export const Wrapper = styled.div`
  width: 100%;
`;

export const SegmentedButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const DesktopStyle = styled.div`
  @media (max-width: ${breakpoint.medium}px) {
    display: none;
  }
`;
export const MobileStyle = styled.div`
  @media (min-width: ${breakpoint.medium}px) {
    display: none;
  }
`;

export const StyledSegmentedButtons = styled.div`
  display: grid;
  grid-auto-columns: minmax(0px, 1fr);
  grid-auto-flow: column;
  border: 1px solid ${colors.monochrome.grey40};
  border-radius: ${spacing.xxsmall};
  overflow: hidden;
`;
