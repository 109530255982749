import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { type PartnerTreeNodeV2 } from '@stenarecycling/customer-portal-types';
import { hasInactiveChildren } from '../../utils';

type InactiveState = {
  hideInactiveLocations: boolean;
  toggleHideInactiveLocations: () => void;
  showInactiveToggle: boolean;
};

export type InactiveStateProviderProps = {
  children: ReactNode;
  locations: PartnerTreeNodeV2[];
  showInactiveToggle: boolean;
  hideInactiveLocations: boolean;
  onInactiveToggle?: (hideInactive: boolean) => void;
};

const InactiveStateContext = createContext<InactiveState | undefined>(undefined);

export const InactiveStateProvider = (props: InactiveStateProviderProps) => {
  const [hideInactiveLocations, setHideInactiveLocations] = useState<boolean>(
    props.hideInactiveLocations,
  );

  const toggleHideInactiveLocations = useCallback(() => {
    setHideInactiveLocations((prevState) => !prevState);
    if (props.onInactiveToggle) {
      //We use the old state since the show/hide is reversed in syntax
      props.onInactiveToggle(hideInactiveLocations);
    }
  }, [hideInactiveLocations, props, setHideInactiveLocations]);

  useEffect(() => {
    setHideInactiveLocations(props.hideInactiveLocations);
  }, [props.hideInactiveLocations]);

  const hasInactiveLocations = useMemo(
    () => hasInactiveChildren(props.locations),
    [props.locations],
  );

  const contextValue: InactiveState = {
    toggleHideInactiveLocations,
    hideInactiveLocations,
    showInactiveToggle: props.showInactiveToggle && hasInactiveLocations,
  };

  return (
    <InactiveStateContext.Provider value={contextValue}>
      {props.children}
    </InactiveStateContext.Provider>
  );
};

export const useInactiveState = (): InactiveState => {
  const contextValue = useContext(InactiveStateContext);

  if (!contextValue) {
    throw new Error('useInactiveState must be used within an InactiveStateProvider');
  }

  return contextValue;
};
