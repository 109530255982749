import { css } from '@emotion/react';
import { colors } from '../../themes/stena-recycling/colors';

import { type Severity } from '../Feedback/Snackbar/SnackbarReducer';
import { spacing } from '../../themes/stena-recycling/spacing';

export const animationTransition = '0.1s ease-in-out';
export const clipPathTop = 'inset(0px -24px -24px -24px);';
export const boxShadow = `0px ${spacing.tiny} ${spacing.small} rgba(0, 0, 0, 0.12)`;
export const modalTransition = '0.3s ease-in-out';
export const overlayColor = 'rgba(30, 30, 30, 0.3)';
export const tertiaryButtonHoverShadeGrey20 = 'rgba(0, 0, 0, 0.1)';

export const getSnackbarColors = (severity: Severity) => {
  switch (severity) {
    case 'success':
      return css`
        background-color: ${colors.alerts.successTint3};
        color: ${colors.alerts.successShade3};
        border: 1px solid ${colors.alerts.success};
      `;
    case 'warning':
      return css`
        background-color: ${colors.alerts.warningTint3};
        color: ${colors.alerts.warningShade3};
        border: 1px solid ${colors.alerts.warning};
      `;
    case 'error':
      return css`
        background-color: ${colors.alerts.errorTint3};
        color: ${colors.alerts.errorShade3};
        border: 1px solid ${colors.alerts.error};
      `;
    case 'info':
      return css`
        background-color: ${colors.alerts.infoTint3};
        color: ${colors.alerts.infoShade3};
        border: 1px solid ${colors.alerts.info};
      `;
  }
};
