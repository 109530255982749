export type Spacing = keyof typeof spacing;

export const spacing = {
  none: '0',
  tiny: '4px',
  xxsmall: '8px',
  xmsmall: '12px',
  xsmall: '16px',
  small: '24px',
  mediumLow: '32px',
  medium: '40px',
  mediumHigh: '48px',
  large: '56px',
  xlarge: '64px',
  xxlarge: '72px',
  huge: '80px',
  xhuge: '96px',
  xxhuge: '128px',
} as const;
