import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useTranslation } from 'react-i18next';
import { type ListDropdownItem } from '@stenarecycling/customer-portal-types';
import { Calendar } from '../../../Icons/Calendar';

import 'dayjs/locale/sv';
import 'dayjs/locale/nb';
import 'dayjs/locale/da';
import 'dayjs/locale/fi';
import 'dayjs/locale/en-gb';
import { getWeekByLanguage } from '../helpers/dateLanguage';
import { colors } from '../../../../themes';
import { type DropdownPropsV2, DropdownV2, SimpleSelectList } from '../../DropdownV2';

dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);

const formatDates = (dates: Date[], resolvedLanguage: string): ListDropdownItem[] => {
  // Set locale for dayjs
  const locale = resolvedLanguage && resolvedLanguage !== 'en' ? resolvedLanguage : 'en-gb';

  dayjs.locale(locale);

  // Filter out dates with same week number
  const filteredDates = dates.filter((date, index) => {
    return (
      index ===
      dates.findIndex((d) => {
        return dayjs(d).week() === dayjs(date).week();
      })
    );
  });

  const getFormat = (resolvedLanguage: string) => {
    switch (resolvedLanguage) {
      case 'sv':
      case 'nb':
      case 'da':
      case 'fi':
        return 'D MMMM';
      default:
        return 'MMM Do';
    }
  };

  // Format the dates to be used in the dropdown
  return filteredDates.map((date) => {
    const firstDayOfWeek = dayjs(date).weekday(0);
    const lastWorkDayOfWeek = dayjs(date).weekday(4);
    const description = `${firstDayOfWeek.format(
      getFormat(resolvedLanguage),
    )} - ${lastWorkDayOfWeek.format(getFormat(resolvedLanguage) + ' YYYY')}`;

    return {
      value: firstDayOfWeek.format('YYYY-MM-DD'),
      label: `${getWeekByLanguage(resolvedLanguage)} ${dayjs(date).week()}`,
      type: 'value',
      children: [],
      meta: { description },
    };
  });
};

export type WeekpickerProps = Omit<
  DropdownPropsV2,
  'options' | 'selectedValues' | 'icon' | 'onChange' | 'placeholder' | 'items'
> & {
  dates: Date[];
  selectedValue: string;
  onChange: (value: string) => void;

  placeholder?: string;
};

export const Weekpicker = ({
  dates,
  selectedValue,
  onChange,
  placeholder,
  ...rest
}: WeekpickerProps) => {
  const { i18n } = useTranslation();
  const resolvedLanguage = i18n.resolvedLanguage ?? 'en';

  const getListItems = () => {
    return formatDates(dates, resolvedLanguage);
  };

  const listItems = getListItems();

  const firstDayOfWeek = dayjs(selectedValue).weekday(0).format('YYYY-MM-DD');

  return (
    <DropdownV2
      {...rest}
      placeholder={placeholder ?? ''}
      selectedValues={[firstDayOfWeek]}
      items={listItems}
      onChange={(values) => values.length && onChange(values[0])}
      multiSelect={false}
      icon={<Calendar color={colors.base.black} width="24px" height="24px" />}
    >
      <SimpleSelectList />
    </DropdownV2>
  );
};
