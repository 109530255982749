import styled from '@emotion/styled';
import { colors } from '../../../themes';
import { boxShadow, clipPathTop } from '../../helpers/styleHelpers';

export const DropdownV2Styled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 150px;
`;

export const DropdownContent = styled.div<{ maxItemsToShow?: number }>`
  border: 1px solid ${colors.monochrome.grey20};
  border-radius: 4px;
  clip-path: ${clipPathTop};
  box-shadow: ${boxShadow};
  background-color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  max-height: ${({ maxItemsToShow }) => (maxItemsToShow ? `${maxItemsToShow * 48}px` : '400px')};
  overflow-y: auto;
  z-index: 1;
`;

export const DropdownContentWrapper = styled.div`
  position: relative;
`;

export const hoverCheckboxStyle = `&:hover {
    .hover-checkbox {
      opacity: 1;
    }
  }`;
